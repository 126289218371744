import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchReportAbuse } from "../../redux/others.ts";
import { Pagination } from "../../components/pagination/index.js";
import { Table } from "../../components/table/index.jsx";
import { Breadcrumbs } from "../../components/breadcrumbs/index.jsx";

export const ReportAbuse = () => {
	const dispatch = useDispatch();
	const { reportAbuseList, isLoading } = useSelector((store) => store.other);

	useEffect(() => {
		dispatch(fetchReportAbuse(1));
	}, []);

	function handlePage(page) {
		dispatch(fetchReportAbuse(page));
	}

	const tableData = [
		{ value: "reason", to: `` },
		{ key: "Reported By", type: "fullname" },
		{ key: "Report Against", value: "course_status" },
		{ key: "Reason to report", value: "reason" },
		{ key: "Report About", value: "data_type" },
		{ key: "Report Date", value: "created_at" },
	];

	return (
		<>
			<Breadcrumbs breadcrumbs={[
					{name: 'Reports', link: ''},
			]} />
			<div className="mx-10 relative">
				<div className="h-[calc(100vh-180px)] overflow-auto my-2 hide-scroll">
					<Table
						data={tableData}
						table={reportAbuseList}
						isLoading={isLoading?.reportAbuse}
					/>
				</div>
				<Pagination
					current={reportAbuseList?.current_page}
					func={handlePage}
					total={reportAbuseList?.last_page}
				/>
			</div>
		</>
	);
};
