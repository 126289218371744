import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getJob } from "../../../redux/jobsSlice.ts";
import { Loader } from "../../../components/loader/index.js";
import { AddBtn } from "../../../components/addBtn/index.js";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";
import { convertDateToWords, dateFormat } from "../../../helper/dateFormat.js";

export const JobDetails = () => {
	const { jobInfo, isLoading } = useSelector((store) => store.jobs);
	const { id } = useParams();
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(getJob(id));
	}, [dispatch, id]);

	return (
		<>
			<Breadcrumbs
				breadcrumbs={[
					{ name: "Jobs", link: "/jobs/view-all" },
					{ name: jobInfo?.title, link: "" },
				]}
			/>
			{isLoading?.getJob ? (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<Loader w="20" h="20" color="orange" />
				</div>
			) : (
				<div className="relative overflow-x-auto h-full px-12 pb-3">
					<table className="w-full text-sm text-left rtl:text-right text-black border shadow-2xl rounded-lg">
						<tbody>
							<tr>
								<td className="px-6 py-4 w-[150px]">
									<AddBtn text={"Edit"} to={`/jobs/edit/${id}`} />
								</td>
							</tr>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Title
								</th>
								<td className="px-6 py-4">
									<div className="max-w-4xl truncate">{jobInfo?.title}</div>
								</td>
							</tr>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Published
								</th>
								<td className="px-6 py-4">
									{jobInfo?.is_published === 0 ? "No" : "Yes"}
								</td>
							</tr>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Description
								</th>
								<td className="px-6 py-4">
									<div
										style={{ wordBreak: "break-word" }}
										className="w-full"
										dangerouslySetInnerHTML={{ __html: jobInfo?.description }}
									/>
								</td>
							</tr>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Country
								</th>
								<td className="px-6 py-4">{jobInfo?.country}</td>
							</tr>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									City
								</th>
								<td className="px-6 py-4">{jobInfo?.city}</td>
							</tr>
							<tr className="border-b ">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Education
								</th>
								<td className="px-6 py-4">
									{jobInfo?.education?.education_level}
								</td>
							</tr>
							<tr className="border-b ">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Employment
								</th>
								<td className="px-6 py-4">{jobInfo?.employment?.name}</td>
							</tr>
							<tr className="border-b ">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Seniority
								</th>
								<td className="px-6 py-4">
									{jobInfo?.seniority?.seniority_name}
								</td>
							</tr>
							<tr className="border-b ">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									External Companies
								</th>
								<td className="px-6 py-4">
									{jobInfo?.companies?.title ||
										jobInfo?.external_companies?.title}
								</td>
							</tr>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Job Url
								</th>
								<td className="px-6 py-4">{jobInfo?.external_link}</td>
							</tr>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Created At
								</th>
								<td className="px-6 py-4">
									{convertDateToWords(jobInfo?.created_at)}
								</td>
							</tr>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Guest User Views
								</th>
								<td className="px-6 py-4">{jobInfo?.view}</td>
							</tr>
							<tr className="border-b">
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									PL Users Views
								</th>
								<td className="px-6 py-4">
									{jobInfo?.views_count}
									<Link
										to={`/jobs/view-users/${jobInfo?.id}`}
										className="underline text-orange-500 ml-5"
									>
										Viewers List
									</Link>
								</td>
							</tr>
							{/* <tr>
								<th
									scope="row"
									className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap"
								>
									Job Applicants
								</th>
								<td className="px-6 py-4">
									{(jobInfo?.applied_candidates || [])?.length}
									<Link
										to={`/jobs/view-applicants/${jobInfo?.id}`}
										className="underline text-orange-500 ml-5"
									>
										Applications
									</Link>
								</td>
							</tr> */}
						</tbody>
					</table>
				</div>
			)}
		</>
	);
};
