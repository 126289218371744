import { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchCourseOrders, deleteCourseOrder } from "../../../../redux/courses.ts";
import { Pagination } from "../../../../components/pagination/index.js";
import DeleteModal from "../../../../components/deleteModal/index.js";
import { isEmpty } from "../../../../helper/join.js";
import { Table } from "../../../../components/table/index.jsx";
import { Breadcrumbs } from "../../../../components/breadcrumbs/index.jsx";

export const PackageBased = () => {
  const dispatch = useDispatch();
  const { courseOrders, isLoading } = useSelector((store) => store.course);
  const [id, setId] = useState(null);
  const deleteRef = useRef(null);

  useEffect(() => {
    dispatch(fetchCourseOrders({ params: {page:1} }));
  }, []);

  function handlePage(page) {
    dispatch(fetchCourseOrders({ params: { page: page } }));
  }

  const handleDelete = (id) => {
    setId(id)
    deleteRef?.current?.open({
      id: id,
      deleteFun: deleteCourseOrder,
      type: "Course Order",
    })
  }

  const tableData = [
		{type: "fullName", nested:true, to: ``},
		{key: "Invoice Num", value: "invoice_num"},
		{key: "Package Name", nested: true, value1: 'plan', value2: 'name'},
    {key: "Discount", nested: true, value1: 'plan', value2: 'discount'},
    {key: "Validity", nested: true, value1: 'plan', value2: 'validity'},
		{key: "Base Price", nested: true, value1: 'plan', value2: 'price'},
		{key: "Currency Code", value: 'currency_code'},
		{key: "Order On", value: "created_at", type:'date'},
	];

	const actions = [
		{type: 'delete', func: handleDelete, isLoading: isLoading?.deleteCourseOrder}
	];

  return (
    <>
      <Breadcrumbs breadcrumbs={[{name: "Package Based Subscription", link: ""}]} />
      <div className="relative mx-12">
          <DeleteModal ref={deleteRef} />
          <div className={`h-[calc(100vh-200px)] overflow-auto mt-5 hide-scroll`}>
            <Table data={tableData} table={courseOrders?.subscriptions} actions={actions} id={id} isLoading={isLoading?.courseOrders} />
          </div>
          <Pagination
              total={courseOrders?.subscriptions?.last_page}
              current={courseOrders?.subscriptions?.current_page}
            func={handlePage}
          />
      </div>
    </>
  );
};
