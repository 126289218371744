import { useState, useImperativeHandle, forwardRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Loader } from "../../../../components/loader/index.js";
import { toastNotification } from "../../../../services/notification-service.js";
import { addCourseTopic } from "../../../../redux/courses.ts";
import ReactPlayer from "react-player";
import { Upload } from "../../../../components/upload/index.jsx";

const AddUpdateTopic = (props, ref) => {
	const { isLoading } = useSelector((store) => store.course);
	const [data, setData] = useState();
	const [show, setShow] = useState(false);
	const [modal, setModal] = useState();
	const dispatch = useDispatch();

	useImperativeHandle(ref, () => ({
		open({ chapter, status, topic, course_slug }) {
			setShow(true);
			setModal(status);
			if (status === "Update") {
				setData({
					chapter_title: topic?.content_name,
					chapter_id: topic?.content_id,
					title: topic?.title,
					course_slug,
					file: topic?.module_video_path || topic?.module_audio_path,
					content_id: topic?.id,
					is_audio: !!topic?.module_audio_path,
				});
			} else {
				setData({
					chapter_title: chapter?.content_name,
					chapter_id: chapter?.id,
					course_slug,
				});
			}
		},
	}));

	const handleFileChange = async (e) => {
		const files = e.target.files;
		const file = files[0];
		if (!file) return;
		const inputThumb = document.getElementById(e.target.id);
		if (!(file?.type?.includes("video") || file?.type?.includes("audio"))) {
			if (inputThumb) {
				inputThumb.value = "";
			}
			toastNotification({
				status: "warning",
				message: "Please upload video/audio file only!",
			});
			return;
		}
		if (file.size > 20 * 1024 * 1024) {
			if (inputThumb) {
				inputThumb.value = "";
			}
			toastNotification({
				status: "error",
				message: "File size exceeds the maximum limit of 20 MB!",
			});
			return;
		}
		setData({ ...data, file: file, is_audio: file?.type?.includes("audio") });
	};

	const handleAdd = (e) => {
		e.preventDefault();
		if (!data?.file) {
			toastNotification({
				status: "error",
				message: "Please upload a video/audio file!",
			});
			return;
		}
		const formData = new FormData();
		const dataToSend = {
			title: data?.title,
			type: "topic",
			course_slug: data?.course_slug,
			chapter_id: data?.chapter_id,
		};

		if (typeof data?.file !== "string") {
			dataToSend["file"] = data?.file;
			dataToSend["file_type"] = data?.file?.type?.includes("video")
				? "video"
				: "audio";
		}

		if (data?.content_id) {
			dataToSend["content_id"] = data?.content_id;
		}

		Object.keys(dataToSend).forEach((key) => {
			formData.append(key, dataToSend[key]);
		});

		dispatch(addCourseTopic({ formData: { formData } }, setShow));
	};

	return (
		<>
			{show && (
				<div
					className="relative z-50"
					aria-labelledby="modal-title"
					role="dialog"
					aria-modal="true"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
					<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<form
								onSubmit={handleAdd}
								className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
							>
								<div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
									<div className="flex flex-col justify-start items-start">
										<div className="relative md:col-start-1 md:col-span-2 z-20 w-full mb-5 group">
											<label className="block mb-2 font-bold text-center text-orange-600 text-lg">
												{data?.chapter_title}
											</label>
										</div>
										<div className="w-full">
											<label className="block mb-2 font-light text-orange-600">
												Topic Name *
											</label>
											<input
												value={data?.title || ""}
												onChange={(e) =>
													setData({ ...data, title: e.target.value })
												}
												type="text"
												className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
												required
											/>
										</div>
										<div className="w-full mt-3">
											<label className="block mb-2 font-light text-orange-600">
												Upload Media File (Video/Audio) *
											</label>
											<Upload id={"file_video_audio"}>
												<input
													id="file_video_audio"
													type="file"
													accept="video/*, audio/*"
													hidden="true"
													name="media_file"
													onChange={handleFileChange}
													max={1}
												/>
											</Upload>

											{data?.is_audio && typeof data?.file === "string" ? (
												<div className="h-full w-full rounded-lg mt-3">
													<audio width="100%" height="100%" controls>
														<source src={data?.file} type="audio/wav" />
													</audio>
												</div>
											) : (
												data?.file && (
													<div className="h-full w-full rounded-lg mt-3 ">
														<ReactPlayer
															url={
																typeof data.file === "string"
																	? data.file
																	: URL.createObjectURL(data.file)
															}
															width="100%"
															height="100%"
															controls
														/>
													</div>
												)
											)}
										</div>
									</div>
								</div>
								<div className="bg-transparent px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
									<button
										type="submit"
										className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
									>
										{isLoading?.addTopic ? (
											<Loader w="6" h="6" color="orange" />
										) : modal === "Add" ? (
											"Add"
										) : (
											"Update"
										)}
									</button>
									<button
										onClick={() => {
											setShow(false);
											setData({});
										}}
										type="button"
										className="inline-flex w-full justify-center border border-orange-600 rounded-md bg-white px-3 py-2 text-sm font-semibold text-orange-600 shadow-sm hover:opacity-90 sm:ml-3 sm:w-auto"
									>
										Back
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default forwardRef(AddUpdateTopic);
