import { useEffect, useState } from "react";
import { Breadcrumbs } from "../../../components/breadcrumbs";
import { useDispatch, useSelector } from "react-redux";
import { fetchPriceList } from "../../../redux/influencersSlice.ts";

export const PriceTagSetting = () => {
	const dataArr = [
		{
			name: "CEO",
			rate: 0,
		},
		{
			name: "Procurement Professionals",
			rate: 0,
		},
		{
			name: "Empoyees",
			rate: 0,
		},
	];

	const [priceList, setPriceList] = useState(dataArr);
	const { priceList: list, isLoading } = useSelector(
		(store) => store?.influencer
	);
	const dispatch = useDispatch();

	useEffect(() => {
		dispatch(fetchPriceList());
	}, []);

	const handlePriceChange = (e) => {
		setPriceList(
			priceList?.map((price) => {
				return e.target.name === price?.name
					? { ...price, rate: e.target.value }
					: price;
			})
		);
	};

	return (
		<>
			<Breadcrumbs
				breadcrumbs={[{ name: "Price Settings (influencers) ---Under-Working-🔨", link: "" }]}
			/>
			<div className="p-4 mx-12 shadow-lg rounded-lg">
				<div className="text-orange-500">Set the per email price </div>
				<div className="flex flex-col justify-start items-start w-full gap-y-4">
					{priceList?.map((price) => {
						return (
							<div className="flex sm:flex-row flex-col justify-between items-start sm:items-center w-3/4">
								<div className="flex justify-between items-center w-1/2">
									<div className="font-light">
										{price?.name} {">>"}
									</div>
									<div className="text-green-600">${price?.rate / 100}</div>
								</div>
								<div className="relative w-full mb-6 mt-5 max-w-80">
									<input
										id="labels-range-input"
										type="range"
										min="0"
										max="10000"
										value={price?.rate}
										name={price?.name}
										onChange={handlePriceChange}
										className="w-full h-2 outline-none accent-orange-500 bg-gray-200 rounded-lg appearance-none cursor-pointer"
									/>
									<span className="text-sm text-gray-500 absolute start-0 -bottom-6">
										$0.01
									</span>
									<span className="text-sm text-gray-500 absolute start-1/3 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">
										$33
									</span>
									<span className="text-sm text-gray-500 absolute start-2/3 -translate-x-1/2 rtl:translate-x-1/2 -bottom-6">
										$67
									</span>
									<span className="text-sm text-gray-500 absolute end-0 -bottom-6">
										$100
									</span>
								</div>
							</div>
						);
					})}
				</div>
			</div>
		</>
	);
};
