import { useParams } from "react-router-dom";
import { CourseDetails } from "./details.js";
import { useLocation, Link } from "react-router-dom";
import { Settings } from "../settings/index.js";
import { Reviews } from "../reviews/index.js";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";
import { useSelector } from "react-redux";
import { capitalize } from "../../../helper/join.js";
import { Chapters } from "../chapters/index.jsx";
import { deslugify } from "../../../helper/paramString.js";

export const Course = () => {
	const { type, title } = useParams();
	const location = useLocation();
	const course_name = useSelector(
		(store) => store.course?.courseDetail?.course_name
	);

	let aside = [{ title: "Details", route: `/offerings/${type}/${title}` }];

	if (type?.includes("course")) {
		aside = [
			...aside,
			{
				title: "Chapters & Topics",
				route: `/offerings/${type}/${title}/chapters`,
			},
		];
	}

	aside = [
		...aside,
		{ title: "Reviews", route: `/offerings/${type}/${title}/reviews` },
		{
			title: "Additional Settings",
			route: `/offerings/${type}/${title}/settings`,
		},
	];

	return (
		<div>
			<aside
				id="sidebar-multi-level-sidebar"
				className="fixed top-16 z-30 w-56 h-screen transition-transform translate-x-0"
				aria-label="Sidebar"
			>
				<div className="h-full px-3 py-4 overflow-y-auto bg-gray-100">
					<ul className="space-y-2">
						{aside.map((item, index) => {
							return (
								<li key={index}>
									<div
										className={`flex items-center p-2 transition rounded-full duration-200 ease-out group ${
											location.pathname === item.route
												? "bg-orange-500 text-white"
												: "text-gray-900 hover:bg-orange-200"
										}`}
									>
										<Link to={item.route} className="ms-3">
											{`> ${item.title}`}
										</Link>
									</div>
								</li>
							);
						})}
					</ul>
				</div>
			</aside>

			<div className="p-4 ml-52 h-max">
				<Breadcrumbs
					breadcrumbs={[
						{ name: capitalize(type), link: `/offerings/${type}` },
						{
							name: `${
								location.pathname.includes("/settings")
									? "Settings"
									: location.pathname.includes("/reviews")
									? "Reviews"
                  : location.pathname.includes("/chapters")
									? "Chapters & Topics"
									: "Details"
							} (${deslugify(title)})`,
							link: location?.pathname,
						},
					]}
				/>
				<div className="p-4 border-2 mt-4 border-gray-200 border-dashed rounded-lg mx-[55px]">
					{location.pathname.includes("/settings") ? (
						<Settings />
					) : location.pathname.includes("/reviews") ? (
						<Reviews />
					) : location.pathname.includes("/chapters") ? (
						<Chapters />
					) : (
						<CourseDetails title={title} />
					)}
				</div>
			</div>
		</div>
	);
};
