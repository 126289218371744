import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Loader } from "../../../../components/loader/index.js";
import { Pagination } from "../../../../components/pagination";
import { fetchLoginActivity } from "../../../../redux/users.ts";
import { useParams } from "react-router";
import { FilterBtn } from "../../../../components/filterBtn/index.js";
import { Unavailable } from "../../../../components/noData/index.js";
import { Breadcrumbs } from "../../../../components/breadcrumbs/index.jsx";

export const LoginActivity = () => {
	const dispatch = useDispatch();
	const [params, setParams] = useState({});
	const { loginActivity, isLoading } = useSelector((store) => store.user);
	const { id } = useParams();

	useEffect(() => {
		setParams({ user_id: id });
		dispatch(fetchLoginActivity({ params: { user_id: id } }));
	}, []);

	function handlePage(page) {
		dispatch(fetchLoginActivity({ params: { ...params, page: page } }));
	}

	const handleClear = () => {
		setParams({ ...params, startDate: "", endDate: "" });
		dispatch(
			fetchLoginActivity({ params: { ...params, startDate: "", endDate: "" } })
		);
	};
	const handleSearch = () => {
		dispatch(fetchLoginActivity({ params: params }));
	};

	return (
		<>
			<Breadcrumbs
				breadcrumbs={[
					{ name: "Active Users", link: "/users/list/1" },
					{ name: "Login Activity", link: "" },
				]}
			/>
			<div className="relative mx-[55px]">
				<div className="flex justify-start items-center space-x-5 my-2">
					<div className="flex flex-col">
						<label>Select Date Range</label>
						<div className="flex flex-row">
							<input
								type="date"
								value={params?.startDate || ""}
								onChange={(e) =>
									setParams({ ...params, startDate: e.target.value })
								}
								className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-40 p-2.5 mt-2"
							/>
							<span className="mt-4 mx-5">-</span>
							<input
								onChange={(e) =>
									setParams({ ...params, endDate: e.target.value })
								}
								type="date"
								value={params?.endDate || ""}
								className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-40 p-2.5 mt-2"
							/>
						</div>
					</div>
					<div className="mt-7">
						<FilterBtn
							func={handleClear}
							disabled={!params?.startDate && !params?.endDate}
							text={"Clear"}
							color={"red"}
						/>
					</div>
					<div className="mt-7">
						<FilterBtn
							func={handleSearch}
							disabled={!params?.startDate || !params?.endDate}
						/>
					</div>
				</div>
				<div className="h-[calc(100vh-270px)] overflow-auto mt-2">
					<table className="w-full text-sm text-left rtl:text-right text-gray-500 border border-black">
						<thead className="text-xs text-orange-500 uppercase bg-gray-100 border border-black">
							<tr>
								<th scope="col" className="px-1 py-3 text-center">
									#
								</th>
								<th scope="col" className="px-6 py-3 text-center">
									Log Type
								</th>
								<th scope="col" className="px-6 py-3 text-center">
									Log Description
								</th>
								<th scope="col" className="px-6 py-3 text-center">
									Date & Time
								</th>
							</tr>
						</thead>
						{isLoading?.loginActivity ? (
							<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
								<Loader h="20" w="20" color={"orange"} />
							</div>
						) : (
							<tbody>
								<Unavailable data={loginActivity} />
								{loginActivity?.data?.map((log, index) => {
									return (
										<tr
											key={index}
											className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap text-center "
										>
											<td className="px-1 py-4 text-center italic border-black">
												{index + loginActivity?.from}
											</td>
											<td className="px-6 py-4 text-center max-w-[200px] truncate">
												{log?.log_name || "-"}
											</td>
											<td className="px-6 py-4 text-center max-w-[200px] truncate">
												{log?.description || "-"}
											</td>
											<td className="px-6 py-4 text-center">
												{log?.created_at?.slice(0, 10)}{" "}
												{log?.created_at?.slice(11, 19)}
											</td>
										</tr>
									);
								})}
							</tbody>
						)}
					</table>
				</div>
				<Pagination
					total={loginActivity?.last_page}
					current={loginActivity?.current_page}
					func={handlePage}
				/>
			</div>
		</>
	);
};
