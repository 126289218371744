import { useEffect, useState } from "react";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";
import { IoWarningOutline } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import {
	fetchPlans,
	fetchSettings,
	updatePlans,
	updateSettings,
} from "../../../redux/systemSettingsSlice.ts";
import { AddBtn } from "../../../components/addBtn/index.js";
import { Loader } from "../../../components/loader/index.js";
import { IoMdCloseCircle } from "react-icons/io";
import { toastNotification } from "../../../services/notification-service.js";
import { convertDateToWords } from "../../../helper/dateFormat.js";

export const SystemSettings = () => {
	const [query, setQuery] = useState({ platform_fee: 0, conversion_rate: 0 });
	const [plans, setPlans] = useState([]);
	const dispatch = useDispatch();
	const {
		plans: systemPlans,
		isLoading,
		settings,
	} = useSelector((store) => store?.systemsettings);

	useEffect(() => {
		setPlans(systemPlans);
	}, [systemPlans]);

	useEffect(() => {
		setQuery({
			...settings?.data,
			platform_fee: Number(settings?.data?.platform_fee) * 10,
		});
	}, [settings?.data]);

	useEffect(() => {
		dispatch(fetchPlans());
		dispatch(fetchSettings());
	}, []);

	const handleChange = (e) => {
		let value = e.target.value;
		let name = e.target.name;
		if (name === "credits") {
			if (value < 0) value = 0;
			if (value > 10000) value = 10000;
		}
		if (name === "price") {
			if (value < 0) value = 0;
			if (value > 99) value = 99;
		}

		setPlans(
			(plans || [])?.map((plan, index) => {
				return Number(e.target.id) === Number(index)
					? { ...plan, [name]: value }
					: plan;
			})
		);
	};

	const handleAddBtn = () => {
		if ((plans || [])?.length < 4) {
			setPlans([...plans, { validity: "W" }]);
		} else {
			toastNotification({
				status: "warning",
				message: "Can't insert more than 4 plans at the moment!",
			});
		}
	};

	const handleUpdateSettings = (e) => {
		e.preventDefault();
		const error = plans?.filter((plan, index) => {
			if (!plan?.name) {
				toastNotification({status: "warning", message: `Please ender valid name of package ${index+1}`});
				return true
			}
			if (!Number(plan?.credits || 0)) {
				toastNotification({status: "warning", message: `Please ender valid number of connects for package ${index+1}`});
				return true
			}
			if (!Number(plan?.price || 0)) {
				toastNotification({status: "warning", message: `Please ender valid price for package ${index+1}`});
				return true
			}

		})
		if ((error || [])?.length !== 0) return
		dispatch(updatePlans({ plans }));
	};

	const handleSave = () => {
		dispatch(
			updateSettings({
				...query,
				platform_fee: Number(query?.platform_fee) / 10,
			})
		);
	};

	return (
		<>
			<Breadcrumbs breadcrumbs={[{ name: "System Settings", link: "" }]} />
			<div className="mx-12 mb-7">
				<div className="flex justify-start p-3 bg-red-50 text-red-600 mb-4 rounded-lg items-center gap-x-3 w-full">
					<IoWarningOutline size={20} /> These Settings are set globally, and
					changes might impact the current user base!
				</div>
				<div className="text-xl font-semibold">System Settings</div>
				{query?.updated_at && (
					<div className="text-[11px] font-light mt-1">
						(Last Updated On: {convertDateToWords(query?.updated_at)})
					</div>
				)}
				<div className="flex flex-col justify-start items-start gap-y-6 mt-8">
					<div className="flex gap-x-4 justify-start items-center w-full">
						<div className="font-light text-sm min-w-96">
							PL Platform Fees/Commission in %age:
						</div>
						<input
							value={query?.platform_fee || 0}
							onChange={(e) =>
								setQuery({ ...query, platform_fee: e.target.value })
							}
							min="0"
							max="1000"
							type="range"
							className="bg-transparent border w-1/4 border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block"
						/>
						<div className="text-sm font-light">
							({(query?.platform_fee || 0) / 10} %)
						</div>
					</div>
					<div className="flex gap-x-4 justify-start items-center w-full">
						<div className="font-light text-sm min-w-96">
							{"Converstion Rate (Dollar -> Connects):"}
						</div>
						<input
							value={query?.conversion_rate || 0}
							onChange={(e) =>
								setQuery({ ...query, conversion_rate: e.target.value })
							}
							type="range"
							min="0"
							max="1000"
							className="bg-transparent w-1/4 border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block"
						/>
						<div className="text-sm font-light">
							({parseInt(query?.conversion_rate || 0)} connects)
						</div>
					</div>
				</div>
				<button
					onClick={() => handleSave()}
					className="border-blue-600 mt-6 capitalize text-blue-600 hover:bg-blue-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
				>
					{isLoading?.updateSettings ? (
						<Loader w={"6"} h={"6"} color={"blue"} />
					) : (
						"Save"
					)}
				</button>

				<div className="text-xl font-semibold mt-5 mb-3">
					System Connect Packages
				</div>
				<AddBtn text={"Add Package"} func={() => handleAddBtn()} />
				{isLoading?.plans ? (
					<div className="flex justify-center items-center w-full mt-16">
						<Loader w={"20"} h={"20"} />
					</div>
				) : (
					<form>
						<div className="flex justify-start w-full items-center gap-8 flex-wrap my-6">
							{(plans || [])?.map((plan, index) => {
								return (
									<div
										key={index}
										className="border py-4 px-10 rounded-lg border-black min-w-96 relative"
									>
										<button
											type="button"
											onClick={() =>
												setPlans(plans?.filter((p, i) => i !== index))
											}
											className="absolute -top-3 -right-3"
										>
											<IoMdCloseCircle size={25} color="red" />
										</button>
										<div className="flex justify-between items-center">
											<div className="flex justify-start flex-col items-start gap-y-3">
												<input
													id={index}
													maxLength={50}
													value={plan?.name || ""}
													type="text"
													name="name"
													onChange={handleChange}
													placeholder="Weekly Super"
													className="font-bold outline-none focus-none text-xl"
													required
												/>
												<div className="font-light">
													Connects:
													<input
														value={plan?.credits}
														type="number"
														name="credits"
														id={index}
														onChange={handleChange}
														placeholder="000"
														required
														className="ml-2 hide-spinners font-light outline-none focus-none"
													/>
												</div>
											</div>
											<div className="font-extrabold text-4xl">
												$
												<input
													value={plan?.price}
													name="price"
													id={index}
													required
													onChange={handleChange}
													placeholder="0"
													type="number"
													className="ml-2 hide-spinners max-w-12 outline-none focus-none"
												/>
												<span className="font-semibold text-xl">/</span>
												<select
													value={plan?.validity}
													onChange={handleChange}
													name="validity"
													id={index}
													required
													placeholder="0"
													type="number"
													className="ml-2 font-semibold text-xl outline-none focus-none"
												>
													<option value={"W"}>week</option>
													<option value={"M"}>month</option>
													<option value={"Y"}>year</option>
												</select>
											</div>
										</div>
									</div>
								);
							})}
						</div>
						<button
							type="submit"
							onClick={handleUpdateSettings}
							className="border-blue-600 capitalize text-blue-600 hover:bg-blue-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
						>
							{isLoading?.updatePlans ? (
								<Loader w={"6"} h={"6"} color={"blue"} />
							) : (
								"Update"
							)}
						</button>
					</form>
				)}
			</div>
		</>
	);
};
