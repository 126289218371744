import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	changeUserStatus,
	fetchUsers,
	setUsers,
} from "../../../redux/users.ts";
import { useNavigate, useParams } from "react-router-dom";
import { Pagination } from "../../../components/pagination/index.js";
import { FilterBtn } from "../../../components/filterBtn/index.js";
import { FilterField } from "../../../components/filterField/index.js";
import { Table } from "../../../components/table/index.jsx";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";
import { removeEmptyString } from "../../../helper/removeEmpty.js";

export const ActiveUsers = () => {
	const [params, setParams] = useState();
	const { users, isLoading } = useSelector((store) => store.user);
	const [id, setId] = useState(null);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { status } = useParams();

	useEffect(() => {
		dispatch(fetchUsers({ params: { status: Number(status) } }));
		setParams({ status: Number(status) });
	}, [status]);

	function pageChange(page) {
		dispatch(fetchUsers({ params: { ...params, page: page } }));
	}

	const handleClearDate = () => {
		let param = { ...params, endDate: "", startDate: "" };
		setParams(param);
		dispatch(fetchUsers({ params: removeEmptyString(param) }));
	};

	const handleClear = () => {
		let param = { ...params, username: "" };
		setParams(param);
		dispatch(fetchUsers({ params: param }));
	};

	const handleView = (id) => navigate(`/users/user-detail/${id}`);

	const handleStatusChange = (id, active) => {
		dispatch(
			changeUserStatus({
				id: id,
				status: active,
			})
		);
		setId(id);
	};

	let tableData = [
		{ type: "fullName", to: "/users/user-detail/{id}" },
		{ type: "stats" },
		{ key: "Registration Date", value: "created_at", type: "date" },
		{ key: "Last Login", value: "last_login", type: "date" },
		{
			key: "Activity Logs",
			name: "Login Activity",
			to: "/users/login-activity/{id}",
		},
	];

	if (Number(status) !== 1) {
		tableData = [
			...tableData,
			{ key: "Deactivation Date", value: "updated_at", type: "date" },
		];
	}

	const actions = [
		{
			type: "activeStatus",
			func: handleStatusChange,
			isLoading: isLoading?.changeStatus,
			value: "is_active",
		},
		{ type: "view", func: handleView, isLoading: false },
	];

	const handleSearch = () => dispatch(fetchUsers({ params: params }));

	return (
		<>
			<Breadcrumbs
				breadcrumbs={[
					{
						name: Number(status) === 1 ? "Active Users" : "Inactive Users",
						link: "",
					},
				]}
			/>
			<div className="relative mx-12">
				<div className="flex flex-row items-start text-xl justify-start space-x-2"></div>
				<div className="flex justify-start items-center space-x-5 my-2">
					<FilterField
						text={"Select Date Range (Last Login)"}
						param={params}
						setParam={setParams}
						type={"date range"}
					/>
					<div className="mt-7">
						<FilterBtn
							func={handleClearDate}
							disabled={!params?.startDate && !params?.endDate}
							text={"Clear"}
							color={"red"}
						/>
					</div>
					<FilterField param={params} setParam={setParams} type={"username"} />
					<div className="mt-7">
						<FilterBtn
							func={handleClear}
							disabled={!params?.username}
							text={"Clear"}
							color={"red"}
						/>
					</div>
					<div className="mt-7">
						<FilterBtn
							func={handleSearch}
							disabled={
								!params?.username && (!params?.startDate || !params?.endDate)
							}
						/>
					</div>
					<div className="flex flex-col">
						<label className="font-light">Sort By</label>
						<select
							onChange={(e) => {
								setParams({ ...params, order: e.target.value });
								dispatch(
									fetchUsers({ params: { ...params, order: e.target.value } })
								);
							}}
							placeholder="Seach by name..."
							type="text"
							value={params?.order || ""}
							className="bg-transparent border text-gray-900 sm:text-sm outline-none focus:border-orange-600 rounded-lg block w-full p-2.5 mt-2"
						>
							<option value={""}>Not Sorted</option>
							<option value={"asc"}>Last Login (Ascending)</option>
							<option value={"desc"}>Last Login (Descending)</option>
						</select>
					</div>
				</div>
				<div className="h-[calc(100vh-280px)] overflow-auto hide-scroll">
					<Table
						data={tableData}
						table={users}
						actions={actions}
						id={id}
						isLoading={isLoading?.users}
					/>
				</div>
				<Pagination
					total={users?.last_page}
					current={users?.current_page}
					func={pageChange}
				/>
			</div>
		</>
	);
};
