import axios from "axios";
import { store } from "../store/index.ts";
import { logout } from "../util/logout.js";
import { toastNotification } from "./notification-service.js";

export default class Api {
  _api = null;
  static requestInterceptor;

  static init = ({ url }) => {
    try {
      this._api = axios.create({
        baseURL: url,
        timeout: 80000,
      });
    } catch (error) {
      return error;
    }
  };

  static clearClientToken() {
    // Eject the interceptor to remove the token
    this._api.interceptors.request.eject(this.requestInterceptor);
  }

  static setClientToken = (token) => {

    // Clear any existing interceptor before setting a new token
    this.clearClientToken();

    this.requestInterceptor = this._api.interceptors.request.use(async (config) => {
      config.headers.Authorization = `Bearer ${token}`;
      return config;
    });
  };

  static verifyResponse = async () => {
    this._api.interceptors.response.use(
      async function (response) {
        if (response?.status === 401 || response?.status === 403) {
          store.dispatch(logout());
        } else if (response?.status === 500) {
          toastNotification({status: "error", message: "Something went wrong!"})
        }
        return response;
      },
      async function (error) {
        if (error?.response?.status === 401 || error?.response?.status === 403) {
          store.dispatch(logout());
        } else if (error?.response?.status === 500) {
          toastNotification({status: "error", message: "Something went wrong!"})
        } else if (!error.response) {
          toastNotification({status: "error", message: "Network Error!"})
        }
        return error;
      }
    );
  };

  static userLogin = async (params) => {
    try {
      const response = await this._api.post(`/login`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static userLogout = async () => {
    try {
      const response = await this._api.get(`/logout`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static fetchCourses = async ({ params }) => {
    try {
      const response = await this._api.post(`/courses/search`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static eventResponse = async ({ params }) => {
    try {
      const response = await this._api.get(`/course/courseInterested`, {params: params});
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static courseStatus = async (id) => {
    try {
      const response = await this._api.post(`/course/${id}/change-status`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static courseDetail = async (title) => {
    try {
      const response = await this._api.get(`/course/detail?slug=${title}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static courseData = async (id) => {
    try {
      const response = await this._api.get(`/course/${id}/details`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static courseCategory = async (params) => {
    try {
      const response = await this._api.get(
        `/course/categories?first=${params.first}&name=${params.name}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static nextCategory = async (cursor) => {
    try {
      const response = await this._api.get(
        `/course-categories?cursor=${cursor}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static courseReviews = async ({ params }) => {
    try {
      const response = await this._api.get(`/course/review/list`, {
        params: params,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static usersList = async ({ user, page }) => {
    try {
      const response = await this._api.get(
        `/users?page=${page}&first=10&slug=${user}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static addReview = async (params) => {
    try {
      const response = await this._api.post(`/course/review/add`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteReview = async (id) => {
    try {
      const response = await this._api.delete(`course/review?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static reviewStatus = async (id) => {
    try {
      const response = await this._api.post(
        `course/review/status-change?id=${id}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static courseSetting = async (title) => {
    try {
      const response = await this._api.get(`/course/setting?slug=${title}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static updateCourseSetting = async (params) => {
    try {
      const response = await this._api.post(`/course/setting`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static courseOrders = async (params) => {
    try {
      const response = await this._api.get(`/course/purchased-items`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteCourseOrder = async (id) => {
    try {
      const response = await this._api.delete(`/course/purchased-items/${id}/deleteSubscription`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static vouchers = async ({ param }) => {
    try {
      const response = await this._api.get(`/voucher/list`, {
        params: param,
      });

      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteVoucher = async (id) => {
    try {
      const response = await this._api.delete(`/voucher?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static voucherPlans = async (id) => {
    try {
      const response = await this._api.get(`/voucher/plans?voucher_id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static addVoucher = async ({ param }) => {
    try {
      const response = await this._api.post(`/voucher/addOrUpdate`, param);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static voucherStatus = async ({ id, checkboxval }) => {
    try {
      const response = await this._api.post(`/voucher/updateStatus?id=${id}`, {
        checkboxval: checkboxval === 0 ? 1 : 0,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static paymentList = async (params) => {
    try {
      const response = await this._api.get(`/payment/billing`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static paymentProcess = async (id) => {
    try {
      const response = await this._api.post(
        `/payment/processPayment?transactionIDForAdmin=${id}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static paymentComplete = async (id) => {
    try {
      const response = await this._api.post(
        `/payment/completePayment?transactionIDForPayment=${id}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static tickets = async (page) => {
    try {
      const response = await this._api.get(`/tickets?first=10&page=${page}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static ticketStatus = async (id) => {
    try {
      const response = await this._api.post(`/ticketUpdate?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static contactUs = async (query) => {
    try {
      const response = await this._api.get(`/contactUs`, {params: query});
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static newsletters = async ({ params }) => {
    try {
      const response = await this._api.get(`/newsletter`, { params: params });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static newslettersCategories = async () => {
    try {
      const response = await this._api.get(`/newsletter/categories`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static addNewsletter = async ({ formData }) => {
    try {
      const response = await this._api.post(
        `/newsletter/addOrUpdate`,
        formData?.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteNewsletter = async (id) => {
    try {
      const response = await this._api.delete(`/newsletter/delete?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static emails = async ({ params }) => {
    try {
      const response = await this._api.get(`/email`, { params: params });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static generateEmail = async ({ formData }) => {
    try {
      const response = await this._api.post(`/email/addOrUpdate`, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteEmail = async (id) => {
    try {
      const response = await this._api.delete(`/email/delete?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static reportAbuse = async (page) => {
    try {
      const response = await this._api.get(
        `/question/reportAbuse?page=${page}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static reports = async ({ params }) => {
    try {
      const response = await this._api.post(`/filter`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static countries = async ({ params }) => {
    try {
      const response = await this._api.get(`/filter/getOptions`, {
        params: params,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static users = async ({ params }) => {
    try {
      const response = await this._api.post(`/adminUser`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static userStatusChange = async ({ id, status }) => {
    try {
      if (status === 0) {
        const response = await this._api.post(
          `/adminUser/activateUser?user_id=${id}`
        );
        return response;
      } else if (status === 1) {
        const response = await this._api.post(
          `/adminUser/deactivateUser?user_id=${id}`
        );
        return response;
      }
    } catch (error) {
      return error.response;
    }
  };

  static userDetail = async (id) => {
    try {
      const response = await this._api.get(
        `/adminUser/userDetail?user_id=${id}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static errorLogs = async (page) => {
    try {
      const response = await this._api.get(`/errorLogs?page=${page}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteErrorLog = async (id) => {
    try {
      const response = await this._api.delete(id ? `/DeleteErrorLogs?id=${id}`:`/DeleteErrorLogs`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static candidateRequests = async ({ params }) => {
    try {
      const response = await this._api.get(`/request/candidate`, {
        params: params,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static employeeRequests = async ({ params }) => {
    try {
      const response = await this._api.get(`/request/employer`, {
        params: params,
      });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static loginActivities = async ({ params }) => {
    try {
      const response = await this._api.get(
        `/adminUser/userActivities`,
        {params: params}
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static userQuestions = async ({ id, page }) => {
    try {
      const response = await this._api.get(
        `/adminUser/getAdminQuestion?user_id=${id}&page=${page}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static userProposals = async ({ id, page }) => {
    try {
      const response = await this._api.get(
        `/adminUser/AdministratorProposals?user_id=${id}&page=${page}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static userContests = async ({ id, page }) => {
    try {
      const response = await this._api.get(
        `/adminUser/getAdminContest?user_id=${id}&page=${page}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static transactions = async ({ params }) => {
    try {
      const response = await this._api.post(
        `/transaction/overAllTransactions`,
        params
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static questionDetails = async (id) => {
    try {
      const response = await this._api.get(
        `/transaction/adminQuestionDetail?id=${id}`
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static adminRoles = async (page) => {
    try {
      const response = await this._api.get(`/roles?page=${page}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static addAdmin = async ({ params }) => {
    try {
      const response = await this._api.post(`/roles/create`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static switchAdminRole = async ({ params }) => {
    try {
      const response = await this._api.post(`/roles/store`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteAdmin = async (id) => {
    try {
      const response = await this._api.delete(`/roles/delete?user_id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static passwordChange = async ({ params }) => {
    try {
      const response = await this._api.post(`/changePassword`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  // team section

  static ourTeam = async (params) => {
    try {
      const response = await this._api.get(`/team`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static teamMemberInfo = async (id) => {
    try {
      const response = await this._api.get(`/team?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static addTeamMember = async ({ formData }) => {
    try {
      const response = await this._api.post(`/team/addOrUpdate`, 
      formData?.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      return response;
    } catch (error) {
      return error.response;
    }
  };

    static updateTeamStatus = async (id) => {
    try {
      const response = await this._api.get(`/team/statusUpdate?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteTeam = async (id) => {
    try {
      const response = await this._api.delete(`/team/deleteTeam?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  // case studies section

  static caseStudies = async (params) => {
    try {
      const response = await this._api.get(`/caseStudy`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static caseStudyInfo = async (id) => {
    try {
      const response = await this._api.get(`/caseStudy?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static addCaseStudy = async ({ formData }) => {
    try {
      const response = await this._api.post(`/caseStudy/addOrUpdateCaseStudy`, 
      formData?.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteCaseStudy = async (id) => {
    try {
      const response = await this._api.delete(`/caseStudy/removeCaseStudy?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static caseStudySections = async (id) => {
    try {
      const response = await this._api.get(`/caseStudy/getSections?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static caseStudySection = async (id) => {
    try {
      const response = await this._api.get(`/caseStudy/getSections?sectionId=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

    static addCaseStudySection = async ({ formData }) => {
    try {
      const response = await this._api.post(`/caseStudy/addOrUpdateSection`, 
      formData?.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteSection = async (id) => {
    try {
      const response = await this._api.delete(`/caseStudy/removeSection?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  // blogs section

  static blogs = async (params) => {
    try {
      const response = await this._api.get(`/blog`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static blogInfo = async (id) => {
    try {
      const response = await this._api.get(`/blog?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static addBlog = async ({ formData }) => {
    try {
      const response = await this._api.post(`/blog/addOrUpdateBlog`, 
      formData?.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteBlog = async (id) => {
    try {
      const response = await this._api.delete(`/blog/removeBlog?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

static categories = async (params) => {
  try {
    const response = await this._api.get(`/blog/getCategories`, params);
    return response;
  } catch (error) {
    return error.response;
  }
};

static categoryInfo = async (id) => {
    try {
      const response = await this._api.get(`/blog/getCategories?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static addCategory = async ({ formData }) => {
    try {
      const response = await this._api.post(`/blog/addOrUpdateCategory`, 
      formData?.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      return response;
    } catch (error) {
      return error.response;
    }
  };

static deleteCategory = async (id) => {
    try {
      const response = await this._api.delete(`/blog/removeBlogCategory?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  // faqs section

  static faqs = async ({ params }) => {
    try {
      const response = await this._api.get(`/faq`, { params: params });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static addFaq = async ({ formData }) => {
    try {
      const response = await this._api.post(`/faq/addOrUpdate`,
        formData?.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deletefaq = async (id) => {
    try {
      const response = await this._api.delete(`/faq/delete?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static faqInfo = async (id) => {
    try {
      const response = await this._api.get(`/faq?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static faqCategories = async (params) => {
    try {
      const response = await this._api.get(`/faq/categories`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static faqCategoryInfo = async (id) => {
    try {
      const response = await this._api.get(`/faq/categories?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static addFaqCategory = async ({ formData }) => {
    try {
      const response = await this._api.post(`/faq/addOrUpdateCategory`,
        formData?.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteFaqCategory = async (id) => {
    try {
      const response = await this._api.delete(`/faq/deleteCategory?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static faqPages = async (params) => {
    try {
      const response = await this._api.get(`/page`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static faqPageInfo = async (id) => {
    try {
      const response = await this._api.get(`/page?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static addFaqPage = async ({ formData }) => {
    try {
      const response = await this._api.post(`/page/addOrUpdate`,
        formData?.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteFaqPage = async (id) => {
    try {
      const response = await this._api.delete(`/page/delete?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static addCourse = async ({formData}) => {
    try {
      const response = await this._api.post(
        `/course/add`,
        formData?.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static editCourse = async ({formData}, id) => {
    try {
      const response = await this._api.post(
        `/course/${id}/details`,
        formData?.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteCourse = async (id) => {
    try {
      const response = await this._api.delete(`/course?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  // jobs section

  static jobs = async ({param}) => {
    try {
      const response = await this._api.post(`/job-central/listing`, param);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static jobInfo = async (id) => {
    try {
      const response = await this._api.get(`/job-central/details/${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static jobCategories = async () => {
    try {
      const response = await this._api.get(`/job-central/filters`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static handleJobFilter = async (param) => {
    try {
      const response = await this._api.post(`/job-central/filters`, param);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static createCompany = async (param) => {
    try {
      const response = await this._api.post(`/create-new-company`, param);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static addJob = async (param) => {
    try {
      const response = await this._api.post(`/job-posting`, param);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static updateJobStatus = async (param) => {
    try {
      const response = await this._api.post(`/job-central/updateByKey`, param);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static jobCentralImport = async ({ formData }) => {
    try {
      const response = await this._api.post(
        `/job-central/import-parse`,
        formData?.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteJob = async (id) => {
    try {
      const response = await this._api.post(`/job-central/updateByKey`, {
        "id":id,
        "key":"is_deleted",
        "value":0
      });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static jobViews = async (param) => {
    try {
      const response = await this._api.get(`/job-views/${param?.id}`, {params:{page: param?.page}});
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static knowledgeBank = async () => {
    try {
      const response = await this._api.get(`/knowledge-bank`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static addKnowledgeBank = async ({ formData }, id) => {
    try {
      const response = await this._api.post(
        id ? `/knowledge-bank/${id}`:`/knowledge-bank` ,
        formData?.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteKnowledgeBank = async (id) => {
    try {
      const response = await this._api.delete(`knowledge-bank/${id}/remove-knowledge-bank`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static getApplicants = async (param) => {
    let par = {...param};
    delete par["id"];
    try {
      const response = await this._api.get(param?.id ? `/job-central/get-applicants/${param?.id}`: `/job-central/get-all-applicants`, {params:par});
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static getApplicationData = async (id) => {
    try {
      const response = await this._api.get(`/job-central/get-applicant-details/${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static updateCandidateApplication = async (params) => {
    try {
      const response = await this._api.post(`/job-central/updateCandidateApplication`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static getPriceListInfluencers = async (id) => {
    try {
      const response = await this._api.get(`/influencer-campaigns`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static dashboardData = async () => {
    try {
      const response = await this._api.get(`/dashboard/pieChart`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static addKnowledgeBankCategory = async (params) => {
    try {
      const response = await this._api.post(`/knowledge-bank-post-category`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static getKnowledgeBankCategory = async () => {
    try {
      const response = await this._api.get(`/knowledge-bank/category`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static getCompanies = async () => {
    try {
      const response = await this._api.get(`/companies`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static getCompanyDetail = async (id) => {
    try {
      const response = await this._api.get(`/companies?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static editUpdateCompany = async (formData) => {
    try {
      const response = await this._api.post(
        `/companies`,
        formData?.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };


  static addChapter = async (params) => {
    try {
      const response = await this._api.post(`/course/course-chapters-and-topics`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static addTopic = async ({formData}) => {
    try {
      const response = await this._api.post(
        `/course/course-chapters-and-topics`,
        formData?.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static courseChapters = async (params) => {
    try {
      const response = await this._api.get(`/course/course-chapters-and-topics`, {params: {course_slug: params}});
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteChaptersTopics = async (params) => {
    try {
      const response = await this._api.delete(`/course/delete-course-chapters-and-topics`, {params: params});
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static getTrainers = async (params) => {
    try {
      const response = await this._api.get(`/trainer`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static addUpdateTrainer = async ({ formData }) => {
    try {
      const response = await this._api.post(`/trainer/addOrUpdate`, 
      formData?.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static updateTrainerStatus = async (id) => {
    try {
      const response = await this._api.get(`/trainer/statusUpdate?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static trainerInfo = async (id) => {
    try {
      const response = await this._api.get(`/trainer?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteTrainer = async (id) => {
    try {
      const response = await this._api.delete(`/trainer/deleteTrainer?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static getPolicies = async (params) => {
    try {
      const response = await this._api.get(`/buying-simplified/policies`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static addUpdatePolicy = async ({ formData }) => {
    try {
      const response = await this._api.post(`/buying-simplified/policies`, 
      formData?.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static getBsUsers = async (params) => {
    try {
      const response = await this._api.get(`/buying-simplified/users`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static addCompanyAttachement = async (formData) => {
    try {
      const response = await this._api.post(
        `/upload-company-attachment`,
        formData?.formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteCompanyAttachment = async (params) => {
    try {
      const response = await this._api.post(`/upload-company-attachment`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static getQueries = async (params) => {
    try {
      const response = await this._api.get(`/companies/queries`, {params: params});
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static deleteQuery = async (id) => {
    try {
      const response = await this._api.delete(`/companies/delete-queries?id=${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static exportCompanies = async (url) => {
    try {
      const response = await this._api.post(`/companies/export`, {url});
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static getInfluencerProfiles = async (params) => {
    try {
      const response = await this._api.get(`/adminUser/creators`, { params: params });
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static changeProfileStatus = async (params) => {
    try {
      const response = await this._api.post(`/adminUser/updateCreatorStatus`, params);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static getUploadedContent = async (query) => {
    try {
      const response = await this._api.get(`/creator/seller-contents`, {params: query});
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static getSingleContent = async (id) => {
    try {
      const response = await this._api.get(`/creator/seller-contents/${id}`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

static changeContentStatus = async (query) => {
    try {
      const response = await this._api.post(`/creator/seller-content/${query?.id}/update`, query);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static getPlans = async () => {
    try {
      const response = await this._api.get(`/plans`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static postPlans = async (query) => {
    try {
      const response = await this._api.post(`/plans`, query);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static getSettings = async () => {
    try {
      const response = await this._api.get(`/settings`);
      return response;
    } catch (error) {
      return error.response;
    }
  };

  static postSettings = async (query) => {
    try {
      const response = await this._api.post(`/settings`, query);
      return response;
    } catch (error) {
      return error.response;
    }
  };

}