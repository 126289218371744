import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import {
	fetchJobApplications,
	setApplicationParams,
	updateApplicantsData,
} from "../../../redux/jobsSlice.ts";
import { useNavigate, useParams } from "react-router-dom";
import { Pagination } from "../../../components/pagination";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";
import { Unavailable } from "../../../components/noData/index.js";
import { Loader } from "../../../components/loader/index.js";
import { convertDateToWords } from "../../../helper/dateFormat.js";
import { debounce } from "lodash";
import { Tooltip } from "../../../components/tooltip/index.jsx";

export const ViewApplicants = () => {
	const { jobApplications, isLoading, applicationParams } = useSelector(
		(store) => store.jobs
	);
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [loader, setLoader] = useState({});
	const { id } = useParams();
	const candidates = id ? jobApplications?.applied_candidates : jobApplications;

	useEffect(() => {
		dispatch(fetchJobApplications({ ...applicationParams, id: id }));
	}, [applicationParams, id]);

	const handlePage = (page) => {
		const par = { ...applicationParams, page: page };
		dispatch(setApplicationParams(par));
	};

	const handleView = (id) => navigate(`/jobs/job-application/${id}`);

	const tableData = [
		{ key: "Full Name", value: "name" },
		{ key: "Job Title", value1: "job", nested: true, value2: "title" },
		{ key: "Email", value: "email" },
		{ key: "Phone Number", value: "phone" },
		{ key: "Status", value: "status", type: "applicationStatus" },
		{ key: "Applied On", value: "created_at", type: "date" },
	];

	const actions = [
		{ type: "view", func: handleView, isLoading: false },
		{ type: "download", isLoading: false },
	];

	const handleStatusChange = (app_id, status) => {
		setLoader({ status: status, id: app_id });
		dispatch(updateApplicantsData({ status: status, id: app_id }));
	};

	const setName = debounce((value) => {
		const par = { ...applicationParams, page: 1, name: value };
		dispatch(setApplicationParams(par));
	}, 1500);

	return (
		<>
			<Breadcrumbs
				breadcrumbs={
					id
						? [
								{ name: "Jobs", link: "/jobs/view-all" },
								{ name: `Job Applicants (${jobApplications?.title || null})`, link: `` },
						  ]
						: [
								{ name: "Jobs", link: "/jobs/view-all" },
								{ name: "All Applications", link: `` },
						  ]
				}
			/>
			<div className="mb-1 relative px-10">
				<div className="flex flex-col max-w-[350px] my-3">
					<label className="font-light">Search By Full Name</label>
					<div className="flex justify-center items-center gap-x-4">
						<input
							onChange={(e) => {
								const inputValue = e.target.value;
								setName.cancel();
								setName(inputValue);
							}}
							placeholder="Start Typing to search..."
							className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
						/>
					</div>
				</div>
				<div className="h-[calc(100vh-270px)] overflow-auto hide-scroll">
					<table className="w-full text-sm text-left text-gray-500 border border-black">
						<thead className="text-sm text-orange-500 uppercase bg-gray-100">
							<tr>
								{tableData
									?.filter?.((tab) => (id ? tab?.key !== "Job Title" : true))
									?.map((tab) => {
										return (
											<th
												scope="col"
												className="px-6 py-3 text-center border border-black"
											>
												{tab?.key}
											</th>
										);
									})}
								<th
									scope="col"
									className="px-6 py-3 border border-black text-center"
								>
									Actions
								</th>
							</tr>
						</thead>
						{isLoading?.jobApplication ? (
							<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
								<Loader h="20" w="20" color={"orange"} />
							</div>
						) : (
							<tbody>
								<Unavailable data={candidates} />
								{candidates?.data?.map((app, index) => {
									return (
										<tr
											key={index}
											className={`bg-white hover:bg-gray-50 ${
												(candidates?.data || [])?.length !== index + 1 &&
												"border-b"
											}`}
										>
											{tableData
												?.filter?.((tab) =>
													id ? tab?.key !== "Job Title" : true
												)
												?.map((tab) => {
													return (
														<td className="px-6 py-4 text-center border-r border-black">
															{tab?.type === "applicationStatus" ? (
																<span
																	className={`${
																		app?.[tab?.value] === 0
																			? "text-orange-500"
																			: app?.[tab?.value] === 1
																			? "text-green-500"
																			: app?.[tab?.value] === 2
																			? "text-red-500"
																			: null
																	}`}
																>
																	{app?.[tab?.value] === 0
																		? "Pending"
																		: app?.[tab?.value] === 1
																		? "Accepted"
																		: app?.[tab?.value] === 2
																		? "Rejected"
																		: null}
																</span>
															) : tab?.type === "date" ? (
																convertDateToWords(app?.[tab?.value])
															) : (
																<Tooltip
																	text={
																		tab?.nested
																			? app?.[tab?.value1]?.[tab?.value2]
																			: app?.[tab?.value]
																	}
																	placement={"top-center"}
																	trigger={
																		<p className="max-w-[200px] truncate">
																			{tab?.nested
																				? app?.[tab?.value1]?.[tab?.value2]
																				: app?.[tab?.value]}
																		</p>
																	}
																/>
															)}
														</td>
													);
												})}
											<td className="px-6 py-4 text-center gap-x-2 justify-center flex items-center">
												{actions?.map((action) => {
													if (action?.type === "download") {
														return (
															<a
																href={app?.resume_path}
																download={`${app?.name}.csv`}
																target="_blank"
																rel="noreferrer"
																className="py-1 text-orange-600 border-2 rounded-lg
                      px-2 border-orange-600 cursor-pointer bg-red hover:bg-orange-600 hover:text-white transition-all duration-200 ease-in-out"
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	fill="none"
																	viewBox="0 0 24 24"
																	stroke-width="1.5"
																	stroke="currentColor"
																	className="w-5 h-5"
																>
																	<path
																		strokeLinecap="round"
																		strokeLinejoin="round"
																		d="M12 9.75v6.75m0 0-3-3m3 3 3-3m-8.25 6a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
																	/>
																</svg>
															</a>
														);
													}
													if (action?.type === "view") {
														return (
															<button
																onClick={() => {
																	action?.func(app?.id);
																}}
																className="py-1 text-blue-600 border-2 rounded-lg
                      px-2 border-blue-600 bg-red hover:bg-blue-600 hover:text-white transition-all duration-200 ease-in-out"
															>
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	fill="none"
																	viewBox="0 0 24 24"
																	strokeWidth="1.5"
																	stroke="currentColor"
																	className="w-5 h-5"
																>
																	<path
																		strokeLinecap="round"
																		strokeLinejoin="round"
																		d="M2.036 12.322a1.012 1.012 0 0 1 0-.639C3.423 7.51 7.36 4.5 12 4.5c4.638 0 8.573 3.007 9.963 7.178.07.207.07.431 0 .639C20.577 16.49 16.64 19.5 12 19.5c-4.638 0-8.573-3.007-9.963-7.178Z"
																	/>
																	<path
																		strokeLinecap="round"
																		strokeLinejoin="round"
																		d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
																	/>
																</svg>
															</button>
														);
													}
												})}
												{app?.status === 0 && (
													<>
														<button
															onClick={() => handleStatusChange(app?.id, 1)}
															className="py-1 text-blue-600 border-2 rounded-lg
                      px-2 border-blue-600 bg-red hover:bg-blue-600 hover:text-white transition-all duration-200 ease-in-out"
														>
															{isLoading?.updateApplicantsData &&
															loader?.status === 1 &&
															loader?.id === app.id ? (
																<Loader w={"5"} h={"5"} color={"blue"} />
															) : (
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	fill="none"
																	viewBox="0 0 24 24"
																	strokeWidth="1.5"
																	stroke="currentColor"
																	className="w-5 h-5"
																>
																	<path
																		strokeLinecap="round"
																		strokeLinejoin="round"
																		d="m4.5 12.75 6 6 9-13.5"
																	/>
																</svg>
															)}
														</button>
														<button
															onClick={() => handleStatusChange(app?.id, 2)}
															className="py-1 text-red-600 border-2 rounded-lg
                      px-2 border-red-600 bg-red hover:bg-red-600 hover:text-white transition-all duration-200 ease-in-out"
														>
															{isLoading?.updateApplicantsData &&
															loader?.status === 2 &&
															loader?.id === app.id ? (
																<Loader w={"5"} h={"5"} color={"red"} />
															) : (
																<svg
																	xmlns="http://www.w3.org/2000/svg"
																	fill="none"
																	viewBox="0 0 24 24"
																	strokeWidth="1.5"
																	stroke="currentColor"
																	className="w-5 h-5"
																>
																	<path
																		strokeLinecap="round"
																		strokeLinejoin="round"
																		d="M6 18 18 6M6 6l12 12"
																	/>
																</svg>
															)}
														</button>
													</>
												)}
											</td>
										</tr>
									);
								})}
							</tbody>
						)}
					</table>
				</div>
				<Pagination
					total={candidates?.last_page}
					current={candidates?.current_page}
					func={handlePage}
				/>
			</div>
		</>
	);
};
