import { Link } from "react-router-dom";
import { useNavigate } from 'react-router-dom';

export const Breadcrumbs = ({ breadcrumbs }) => {
	const navigate = useNavigate();

	const goBack = () => {
		navigate(-1);
	};	

	return (
		<>
			<div
				className={`flex justify-start items-center gap-x-5 ${
					breadcrumbs?.length > 1 ? "mx-3" : "mx-12"
				} pt-3`}
			>
				{breadcrumbs?.length > 1 && (
					<button onClick={goBack}>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="currentColor"
							className="w-6 h-6 stroke-black hover:stroke-orange-500"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M15.75 19.5 8.25 12l7.5-7.5"
							/>
						</svg>
					</button>
				)}
				<div className="flex justify-center text-[12px] gap-x-2 tracking-wider font-light">
					<div className="flex justify-center gap-x-2">
						<Link to={"/dashboard"} className="hover:text-orange-500">
							Dashboard
						</Link>
						{breadcrumbs && <div>|</div>}
					</div>
					{breadcrumbs?.map((breadcrumb, index) => {
						return (
							<div key={index} className="flex justify-center gap-x-2 ">
								<Link to={breadcrumb?.link} className="hover:text-orange-500 max-w-[250px] truncate">
									{breadcrumb?.name}
								</Link>
								{breadcrumbs?.length - 1 !== index && <div>|</div>}
							</div>
						);
					})}
				</div>
			</div>
			<div
				className={`${
					breadcrumbs?.length > 1 ? "mx-[55px]" : "mx-12"
				} my-3 bg-gray-400 h-[1px]`}
			/>
		</>
	);
};
