export const FilterBtn = ({ func, disabled, text, color }) => {
	return (
		<button
			disabled={disabled ?? false}
			onClick={() => func()}
			className={`shrink-0 ${
				color
					? `border-${color}-600 text-${color}-600 hover:bg-${color}-600`
					: `border-blue-600 text-blue-600 hover:bg-blue-600`
			} bg-white px-3 py-1 border-2 rounded-full hover:text-white transition-all duration-200 ease-in-out ${
				(!text || text === "Search") && "flex justify-center items-center"
			}`}
		>
			{text ?? "Search"}
			{(!text || text === "Search") && (
				<svg
					xmlns="http://www.w3.org/2000/svg"
					fill="none"
					viewBox="0 0 24 24"
					strokeWidth="1.5"
					stroke="currentColor"
					className="ml-1 w-5 h-5"
				>
					<path
						strokeLinecap="round"
						strokeLinejoin="round"
						d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
					/>
				</svg>
			)}
		</button>
	);
};
