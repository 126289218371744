import { useState, Fragment, useEffect } from "react";
import { Combobox, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import DraftEditor from "../../components/DraftEditor/index.js";
import { fetchCategories, addBlog } from "../../redux/blogsSlice.ts";
import { Loader } from "../../components/loader/index.js";
import { useNavigate } from "react-router-dom";
import { FaCheckCircle } from "react-icons/fa";
import { Breadcrumbs } from "../../components/breadcrumbs/index.jsx";
import { Upload } from "../../components/upload/index.jsx"

export const AddBlog = () => {
  const [data, setData] = useState();
  const [draft, setDraft] = useState();
  const { categories, isLoading } = useSelector((store) => store.blogs);
  const dispatch = useDispatch();
  const [imagePreview, setImagePreview] = useState(null);
  const navigate = useNavigate();
  const [params, setParams] = useState({ page: 1 });
  const [error, setError] = useState("");
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [query, setQuery] = useState('');

  useEffect(() => {
    dispatch(fetchCategories({ params: params }));
  }, []);

  const handleFileChange = async (e) => {
  const file = e.target.files?.[0];

  if (!file) return
  if (file?.size / 1024 > 1024) {
    setError("Size exceeded limit of 1024 KB!");
  } else {    
    setData({ ...data, image: file });
  }
};

    const handleAdd = (e) => {
    e.preventDefault();
    const formData = new FormData();
    const combinedData = { ...data, content: draft };
      if (!combinedData.image || !combinedData.title || !combinedData.content || combinedData.content === "<p></p>\n") {
        const errorMessage = !combinedData.title ? "Title is required field." : !combinedData.image ? "Image is required field." :
          !combinedData.content || combinedData.content === "<p></p>\n" ? "Content is required field." : "";
        setError(errorMessage);
        return;
      }
      if ((data?.categories || [])?.length === 0) return setError("Select Atleast 1 category!");
    data?.categories?.forEach((category, index) => {
      formData.append(`categories[${index}]`, category.id);
    });
      delete combinedData.categories;

      if (!('categories' in combinedData)) {
      Object.keys(combinedData).forEach((key) => {
        formData.append(key, combinedData[key]);
      });
    }
      setError("");
      dispatch(addBlog({ formData: { formData } }, navigate));
  };

  const handleSelected = (category) => {
    if (category) {
      setSelectedCategories((prevSelected) => {
        const updatedSelected = [...prevSelected, category];
        setData((prevData) => ({ ...prevData, categories: updatedSelected }));
        return updatedSelected;
      });
    }
  };

   const filteredCategories =
    query === ''
      ? categories
      : categories?.filter((category) =>
          category?.name
            ?.toLowerCase()
            ?.replace(/\s+/g, '')
            ?.includes(query.toLowerCase().replace(/\s+/g, ''))
        )
  
  return (
    <>
      <Breadcrumbs
					breadcrumbs={[
						{ name:"Blogs", link: '/contents/blogs'},
            { name:"Add Blog", link: ''},
					]}
				/>
      <div className="bg-white mx-[55px] py-5 rounded-lg shadow-lg p-6">
        <form onSubmit={handleAdd} className="flex flex-col justify-center items-center mt-5">
          <div className="w-full mx-auto">
            <div className="grid md:grid-cols-4 md:gap-20">
              <div className="relative md:col-start-1 md:col-span-2  w-full mb-5 group">
                <input
                  type="text"
                  name="title"
                  id="title"
                  className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-orange-500 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
                  placeholder=" "
                  value={data?.title || ''}
                  onChange={(e) =>
                    setData({ ...data, title: e.target.value })
                  }
                />
                <label
                  htmlFor="title"
                  className="peer-focus:font-medium absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Title *
                </label>
              </div>
              <div className="md:col-start-3 md:col-span-2 relative z-99 w-full mb-5 group">
              <Combobox value={selectedCategories} onChange={handleSelected}>
                  <div className="relative mt-2">
                    <div className="relative w-full cursor-default overflow-hidden text-left">
                      <Combobox.Input
                        className="border-b-2 border-orange-500 focus:border-orange-600 text-gray-900 block w-full py-1 outline-none placeholder:text-gray-400"
                        onChange={(event) => setQuery(event.target.value)}
                        placeholder="Select Categories *"
                        autoComplete="off"
                      />
                    </div>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                      afterLeave={() => setQuery('')}
                    >
                      <Combobox.Options className="absolute z-[999] mt-1 max-h-40 w-full overflow-auto ounded-md bg-white py-1 text-base">
                        {filteredCategories.length === 0 && query !== '' ? (
                          <div className="relative cursor-default select-none px-4 py-2 text-gray-700">
                            Nothing found.
                          </div>
                        ) : (
                          (filteredCategories || [])
                          ?.filter(category => !selectedCategories.some(sel => sel?.id === category?.id))
                            ?.map((category) => (
                            <Combobox.Option
                              key={category?.id}
                              className={({ active }) =>
                                `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                  active ? "bg-orange-600 text-white" : "text-gray-900"
                                }`
                              }
                              value={category}
                            >
                              {({ selected, active }) => (
                                <>
                                  <span
                                    className={`block truncate ${
                                      selected ? 'font-medium' : 'font-normal'
                                    }`}
                                  >
                                    {category?.name}
                                  </span>
                                  {selected ? (
                                    <span
                                      className="absolute inset-y-0 left-0 flex items-center pl-3 text-amber-600"
                                    >
                                      <FaCheckCircle className="h-5 w-5" aria-hidden="true" />
                                    </span>
                                  ) : null}
                                </>
                              )}
                            </Combobox.Option>
                          ))
                        )}
                      </Combobox.Options>
                    </Transition>
                  </div>
                </Combobox>
              </div>
            </div>
            <div className="flex justify-start items-start gap-x-2 my-4">
              {selectedCategories.map((item) => (
                <>
            <div className="flex justify-center items-center gap-x-2 text-[10px] px-3 bg-gray-50 py-2 rounded-full">
            <p>{item?.name}</p>
            <button
            type="button"
          onClick={() => {
            setSelectedCategories((prevSelected) =>
              prevSelected.filter((selected) => selected.id !== item.id)
            );
            setData((prevData) => ({
              ...prevData,
              categories: prevData.categories.filter((selected) => selected.id !== item.id)
            }));
          }}
        >
          <svg
            className="h-3 w-3"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            stroke="red"
            aria-hidden="true"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              d="M6 18L18 6M6 6l12 12"
            />
          </svg>
        </button>
            </div>
                </>
              ))}
              </div>
            <div>
            <div className="text-sm text-orange-600 mb-3">
              Content *
            </div>
            <div className="relative w-full mb-5 group border border-black">
              <DraftEditor onChange={setDraft} />
            </div>
            </div>
            <div className="md:grid md:grid-cols-3 mt-5">
              <div className="relative  w-full mb-5 group md:col-start-1 md:col-span-1">
                <label
                  htmlFor="thumbnail"
                  className="peer-focus:font-medium absolute text-sm text-orange-500 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
                >
                  Blog Image *
                </label>
                <Upload id={"blog-image"}>
										<input
											id="blog-image"
											type="file"
											hidden="true"
											accept=".jpeg, .jpg, .png, .gif, image/jpeg, image/jpg, image/png"
											name="support_files"
											onChange={handleFileChange}
											max={1}
										/>
									</Upload>
              </div>
            </div>
            {data?.image && (
              <img
                src={URL.createObjectURL(data?.image)}
                alt="Uploaded Preview"
                className="mb-4 w-24 h-24 rounded"
              />
            )}
            <button
              className="border-blue-600 capitalize text-blue-600 hover:bg-blue-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out"
            >
              {isLoading?.addBlog ? (
              <div>
                <Loader h="6" w="6" color={"blue"} />
              </div>
            ) : (
              "Add"
            )}
            </button>
            {error && <p className="my-2 text-red-500">{error}</p>}
          </div>
        </form>
      </div>
    </>
  );
};
