import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import DraftEditor from "../../components/DraftEditor/index.js";
import { addCaseStudy, getCaseStudy } from "../../redux/caseStudiesSlice.ts";
import { Loader } from "../../components/loader/index.js";
import { useNavigate } from "react-router-dom";
import { Breadcrumbs } from "../../components/breadcrumbs/index.jsx";

export const EditCaseStudy = () => {
	const [draft, setDraft] = useState();
	const { caseStudyInfo, isLoading } = useSelector(
		(store) => store.caseStudies
	);
	const [data, setData] = useState();
	const [error, setError] = useState("");
	const dispatch = useDispatch();
	const { id } = useParams();
	const navigate = useNavigate();

	useEffect(() => {
		dispatch(getCaseStudy(id));
	}, [dispatch, id]);

	useEffect(() => {
		setData(caseStudyInfo);
		setDraft(caseStudyInfo?.conclusion);
	}, [caseStudyInfo]);

	const handleEdit = (e) => {
		e.preventDefault();
		const formData = new FormData();
		const combinedData = { ...data, conclusion: draft };
		console.log(combinedData.conclusion);
		if (
			!combinedData.title ||
			!combinedData.conclusion ||
			combinedData.conclusion === "<p><br></p>"
		) {
			const errorMessage = !combinedData.title
				? "Title is required field."
				: !combinedData.conclusion || combinedData.conclusion === "<p><br></p>"
				? "Conclusion is required field."
				: "";
			setError(errorMessage);
			return;
		}
		delete combinedData.sections;
		delete combinedData.created_at;
		delete combinedData.updated_at;

		Object.keys(combinedData).forEach((key) => {
			formData.append(key, combinedData[key]);
		});
		setError("");
		dispatch(addCaseStudy({ formData: { formData } }, navigate));
	};

	return (
		<>
			<Breadcrumbs
				breadcrumbs={[
					{ name: "Case Studies", link: "/contents/case-studies" },
					{
						name: "Case Study Details",
						link: `/contents/case-studies/${id}/details`,
					},
					{ name: "Edit Case Study", link: `` },
				]}
			/>
			{isLoading?.caseStudyInfo ? (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<Loader w="20" h="20" color="orange" />
				</div>
			) : (
				<div className="bg-white px-12 mb-4">
					<form
						onSubmit={handleEdit}
						className="flex flex-col justify-center p-4 items-center shadow-lg rounded-lg"
					>
						<div className="w-full mx-auto">
							<div className="grid md:grid-cols-4 md:gap-20">
								<div className="relative md:col-start-1 md:col-span-2  w-full mb-5 group">
									<input
										type="text"
										name="title"
										id="title"
										className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
										placeholder=" "
										value={data?.title || ""}
										onChange={(e) =>
											setData({ ...data, title: e.target.value })
										}
										required
									/>
									<label
										htmlFor="title"
										className="peer-focus:font-medium absolute text-sm text-orange-600 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 rtl:peer-focus:left-auto peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
									>
										Title<span className="text-red-600">*</span>
									</label>
								</div>
								<div className="md:col-start-3 md:col-span-2 relative  w-full mb-5 group">
									<input
										type="text"
										name="subtitle"
										id="subtitle"
										className="block py-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-orange-600 peer"
										placeholder=" "
										value={data?.sub_title || ""}
										onChange={(e) =>
											setData({ ...data, sub_title: e.target.value })
										}
										required
									/>
									<label
										htmlFor="subtitle"
										className="peer-focus:font-medium absolute text-sm text-orange-600 duration-300 transform -translate-y-6 scale-75 top-3  origin-[0] peer-focus:start-0 rtl:peer-focus:translate-x-1/4 peer-focus:text-orange-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6"
									>
										Sub Title *
									</label>
								</div>
							</div>
							<div>
								<div className="text-sm text-orange-600 mb-3">Conclusion *</div>
								<div className="relative  w-full mb-5 group border border-black">
									<DraftEditor onChange={setDraft} title={data?.conclusion} />
								</div>
								{draft === "<p><br></p>" && (
									<div className="text-xs text-red-500">
										This Field is required!
									</div>
								)}
							</div>
							<button className="border-blue-600 capitalize text-blue-600 hover:bg-blue-600 bg-white px-3 py-2 border-2 rounded-lg hover:text-white transition-all duration-200 ease-in-out">
								{isLoading?.addCaseStudy ? (
									<div>
										<Loader h="6" w="6" color={"blue"} />
									</div>
								) : (
									"Update"
								)}
							</button>
							{error && <p className="my-2 text-red-500">{error}</p>}
						</div>
					</form>
				</div>
			)}
		</>
	);
};
