import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchContactUs } from "../../redux/others.ts";
import { Pagination } from "../../components/pagination/index.js";
import { Table } from "../../components/table/index.jsx";
import { Breadcrumbs } from "../../components/breadcrumbs/index.jsx";
import View from "./View/index.jsx";

export const ContactUs = ({ type }) => {
	const dispatch = useDispatch();
	const modalRef = useRef(null);
	const { contactUs, isLoading } = useSelector((store) => store.other);

	useEffect(() => {
		let query = { page: 1 };
		if (type) {
			query.includes = type;
		} else {
			query.excludes = "rfq, request-join-research, expert-hiring-form";
		}
		dispatch(fetchContactUs(query));
	}, [type]);

	function handlePage(page) {
		let query = { page: page };
		if (type) {
			query.includes = type;
		} else {
			query.excludes = "rfq, request-join-research, expert-hiring-form";
		}
		dispatch(fetchContactUs(query));
	}

	let tableData = [
		{ value: "user_name", to: `` },
		{ key: "Email", value: "user_email" },
		{ key: "Ticket Type", value: "ticket_type" },
		{ key: "Reason", value: "reason" },
		{ key: "Created At", value: "created_at", type: "date" },
	];

	if (type === "rfq" || type === "request-join-research") {
		tableData = [
			{ value: "user_name", to: `` },
			{ key: "Email", value: "user_email" },
			{ key: "Company", value: "reason" },
			{ key: "Created At", value: "created_at", type: "date" },
		];
	}

	if (type === "expert-hiring-form") {
		tableData = [
			{ value: "user_name", to: `` },
			{ key: "Email", value: "user_email" },
			{ key: "Created At", value: "created_at", type: "date" },
		];
	}

	const handleView = (a, b, tab) => {
		modalRef.current.open(tab);
	};

	const actions = [{ type: "view", func: handleView, isLoading: false }];

	return (
		<>
			<View type={type} ref={modalRef} />
			<Breadcrumbs
				breadcrumbs={[
					{
						name:
							type === "rfq"
								? "Quote Requests"
								: type === "request-join-research"
								? "Join Research Requests"
								: type === "expert-hiring-form"
								? "Expert Hiring Form"
								: "Action List",
						link: "",
					},
				]}
			/>
			<div className="mx-10 relative">
				<div className="h-[calc(100vh-190px)] overflow-auto my-2 hide-scroll">
					<Table
						data={tableData}
						table={contactUs}
						isLoading={isLoading?.actions}
						actions={actions}
					/>
				</div>
				<Pagination
					current={contactUs?.current_page}
					func={handlePage}
					total={contactUs?.last_page}
				/>
			</div>
		</>
	);
};
