import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchTeam, deleteTeam, updateTeamStatus } from "../../redux/teamSlice.ts";
import { Pagination } from "../../components/pagination/index.js";
import { AddBtn } from "../../components/addBtn/index.js";
import { FilterBtn } from "../../components/filterBtn/index.js";
import { FilterField } from "../../components/filterField/index.js";
import DeleteModal from "../../components/deleteModal/index.js";
import { Table } from "../../components/table/index.jsx";
import { Breadcrumbs } from "../../components/breadcrumbs/index.jsx";

export const OurTeam = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { team, isLoading } = useSelector((store) => store.team);
  const [id, setId] = useState("");
  const [params, setParams] = useState({ page: 1 });
  const deleteRef = useRef(null);

  useEffect(() => {
    dispatch(fetchTeam({ params: params }));
  }, []);

  function handlePage(page) {
    dispatch(fetchTeam({ params: { ...params, page: page } }));
  }

  const handleSearch = () => {
    dispatch(fetchTeam({ params: params }));
  }

  const handleClear = () => {
    setParams({});
    dispatch(fetchTeam({ params: { page: 1 } }));
  };

  const handleStatusChange = (id) => {
    const data = { params: params };
    dispatch(updateTeamStatus(id, data));
    setId(id);
  }

  const handleView = (id) =>
  navigate(
    `/contents/team/${(id)}`
  )

  const handleDelete = (id) => {
    setId(id)
    deleteRef?.current?.open({
      id: id,
      deleteFun: deleteTeam,
      type: "Team Member",
    })
  }

  const tableData = [
		{value: "name", to: `/contents/team/{id}`, img:'image_path'},
		{key: "Title", value: "title"},
		{key: "Status", value: "is_active", type:'status'},
		{key: "Added On", value: "created_at", type: 'date'},
	];

	const actions = [
		{type: 'status', func:handleStatusChange, isLoading: isLoading?.updateTeamStatus, value:'is_active'},
		{type: 'view', func: handleView, isLoading: false},
		{type: 'delete', func: handleDelete, isLoading: isLoading?.deleteTeam}
	];

  return (
    <>
      <Breadcrumbs breadcrumbs={[
            {name: 'Our Team', link: ''},
      ]} />
      <div className="relative mx-12">
        <DeleteModal ref={deleteRef} />
        <div className="flex justify-between items-center space-x-10">
          <AddBtn
            to={"/contents/team/add"}
            mt={5}
            text={"Add Team Member"}
          />
          <div className="flex gap-2">
            <FilterField
              param={params}
              setParam={setParams}
              type={"name"}
            />
            <FilterField
              param={params}
              setParam={setParams}
              type={"title"}
            />
          <div className="flex justify-center items-center px-2 mt-6 gap-2">
            <FilterBtn
              func={handleSearch}
                disabled={!params?.title && !params?.name}
            />
            <FilterBtn
              func={handleClear}
                disabled={!params?.title && !params?.name}
              text={"Clear"}
              color={"red"}
            />
          </div>
          </div>
        </div>
        <div className="h-[calc(100vh-270px)] overflow-auto mt-5 hide-scroll">
          <Table data={tableData} table={team} actions={actions} id={id} isLoading={isLoading?.fetchTeam} />
        </div>
        <Pagination
          total={team?.last_page}
          current={team?.current_page}
          func={handlePage}
        />
      </div>
    </>
  );
};
