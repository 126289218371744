import { Modal } from "../../../../components/modal"
import { AddUpdateJobs } from "../../addUpdate"

export const JobModal = ({show, setShow, data, setData}) => {

    return (
        <Modal show={show} setShow={setShow} size={"lg"}>
            <AddUpdateJobs modal={true} info={data} setShow={setShow} />
        </Modal>
    )
}