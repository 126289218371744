import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	deleteVoucher,
	fetchVouchers,
	setVoucherParams,
	voucherStatusUpdate,
} from "../../../redux/vouchers.ts";
import { debounce } from "lodash";
import { useNavigate } from "react-router-dom";
import { Pagination } from "../../../components/pagination/index.js";
import { AddBtn } from "../../../components/addBtn/index.js";
import DeleteModal from "../../../components/deleteModal/index.js";
import { Table } from "../../../components/table/index.jsx";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";

export const Vouchers = () => {
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { voucherList, isLoading, voucherParam } = useSelector((store) => store.voucher);
	const [param, setParam] = useState(voucherParam ||{ first: 12, page: 1 });
	const [id, setId] = useState();
	const [isMounted, setIsMounted] = useState(false);
	const popupRef = useRef();

	useEffect(() => {
		if (isMounted) {
			dispatch(fetchVouchers({ param: { ...param, page: 1} }));
			dispatch(setVoucherParams({ ...param, page: 1}));
		} else {
			setIsMounted(true);
		}
	}, [param?.status, param?.voucher]);

	useEffect(() => {
		dispatch(fetchVouchers({ param: { ...param} }));
	}, []);

	function handlePage(page) {
		dispatch(fetchVouchers({ param: { ...param, page: page } }));
		dispatch(setVoucherParams({ ...param, page: page}))
	}

	const setVoucher = debounce((value) => {
		setParam({ ...param, voucher: value });
	}, 1500);

	const handleDelete = (id) => {
		setId(id);
		popupRef?.current?.open({
			id: id,
			deleteFun: deleteVoucher,
			type: "Voucher",
		});
	};

	const handleCheckBox = (id, status) =>
		dispatch(
			voucherStatusUpdate({
				id: id,
				checkboxval: status,
				param: {param: voucherParam}
			})
		);

	const handleEdit = (id) => navigate(`/payment-request/update-voucher/${id}`);

	const tableData = [
		{ value: "voucher", to: `` },
		{ key: "Discount", value: "discount" },
		{ key: "Valid From", value: "valid_from", type: "date" },
		{ key: "Valid To", value: "expiry_date", type: "date" },
		{ key: "Status", value: "is_active", type: "status" },
		// { key: "Banner", value: "status", type: "status" },
		{ key: "Plan", nested: true, value1: "plan", value2: "name" },
		{ key: "Created At", value: "created_at", type: "date" },
	];

	const actions = [
		// { type: "checkbox", func: handleCheckBox, isLoading: false },
		{ type: "edit", func: handleEdit, isLoading: false },
		{ type: "delete", func: handleDelete, isLoading: isLoading?.deleteVoucher },
	];

	return (
		<>
			<Breadcrumbs breadcrumbs={[
				{name: "Vouchers List", link: ""},
			]} />
			<div className="relative px-12">
				<DeleteModal ref={popupRef} />
				<div className="flex justify-start items-center space-x-10 my-2">
					<AddBtn to={"/payment-request/add-voucher"} mt={6} text={"Create"} />
					<div className="flex flex-col">
						<label className="font-light">Voucher</label>
						<input
							onChange={(e) => {
								const inputValue = e.target.value;
								setVoucher.cancel();
								setVoucher(inputValue);
							}}
							defaultValue={voucherParam?.voucher}
							className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
						/>
					</div>
					{/* <div className="flex flex-col">
						<label className="font-light">Vouchers Banners</label>
						<select
							onChange={(e) => setParam({ ...param, status: e.target.value })}
							type="select"
							defaultValue={voucherParam?.status}
							className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
						>
							<option value={""}>Select</option>
							<option value={1}>Displayed</option>
							<option value={0}>Hidden</option>
						</select>
					</div> */}
				</div>
				<div className="h-[calc(100vh-270px)] overflow-auto hide-scroll">
					<Table
						data={tableData}
						table={voucherList}
						actions={actions}
						id={id}
						isLoading={isLoading?.vouchers}
					/>
				</div>
				<Pagination
					total={voucherList?.last_page}
					current={voucherList?.current_page}
					func={handlePage}
				/>
			</div>
		</>
	);
};
