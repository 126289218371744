import { useState, useImperativeHandle, forwardRef } from "react";
import DraftEditor from "../../components/DraftEditor";
import { useSelector, useDispatch } from "react-redux";
import { addNewsLetter } from "../../redux/others.ts";
import { Loader } from "../../components/loader/index.js";

const AddNew = (props, ref) => {
	const [draft, setDraft] = useState();
	const [imagePreview, setImagePreview] = useState(null);
	const { newsCategories, newsletters, isLoading } = useSelector(
		(store) => store.other
	);
	const [data, setData] = useState();
	const [size, setSize] = useState("");
	const [show, setShow] = useState(false);
	const [modal, setModal] = useState();
	const [desc, setDesc] = useState();
	const dispatch = useDispatch();

	useImperativeHandle(ref, () => ({
		open({ id, status }) {
			setShow(true);
			setModal(status);
			setImagePreview();
			if (status === "Update") {
				const UpdateData = newsletters?.data?.filter((news) => {
					return news?.id === id;
				});
				setData({
					id: id,
					category_id: UpdateData[0]?.category?.id,
					title: UpdateData[0]?.title,
					description: UpdateData[0]?.description,
					thumbnail: UpdateData[0]?.thumbnail,
				});
				setDesc(UpdateData[0]?.description);
				setDraft(UpdateData[0]?.description);
			} else {
				setData({});
				setDesc("");
				setDraft("");
			}
		},
	}));

	const handleFileChange = async (e) => {
		const files = e.target.files;
		if (!files?.[0]?.type?.includes("image")) {
			setSize("Please upload image file only!");
			return;
		}
		if (files[0]?.size / 1024 > 1024) {
			setSize("Size exceeded limit of 1024 KB!");
		} else {
			setSize("");
			for (let i = 0; i < files.length; i++) {
				const file = files[i];
				setData({ ...data, thumbnail: file });
				const reader = new FileReader();
				reader.onloadend = () => {
					// Set the image preview using the data URL
					setImagePreview(reader.result);
				};
				reader.readAsDataURL(file);
			}
		}
		const inputThumb = document.getElementById(e.target.id);
		if (inputThumb) {
			inputThumb.value = "";
		}
	};

	const handleAdd = (e) => {
		e.preventDefault();
		const formData = new FormData();
		if (!size && data?.description !== "<p></p>\n") {
			const combinedData = { ...data, description: draft };

			if (typeof combinedData?.thumbnail === "string") {
				delete combinedData?.thumbnail;
			}

			Object.keys(combinedData).forEach((key) => {
				formData.append(key, combinedData[key]);
			});

			dispatch(addNewsLetter({ formData: { formData }, setShow: setShow }));
		}
	};

	return (
		<>
			{show && (
				<div
					className="relative z-50"
					aria-labelledby="modal-title"
					role="dialog"
					aria-modal="true"
				>
					<div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
					<div className="fixed inset-0 z-10 w-screen overflow-y-auto">
						<div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
							<form
								onSubmit={handleAdd}
								className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg"
							>
								<div className="bg-white px-4 pb-4 pt-5 sm:p-6 sm:pb-4">
									<div className="flex flex-col justify-start items-start">
										<div className="relative md:col-start-1 md:col-span-2 z-20 w-full mb-5 group">
											<label className="block mb-2 text-sm font-medium text-gray-900">
												Category
											</label>
											<select
												placeholder="Out of 5"
												type="text"
												className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
												value={data?.category_id}
												onChange={(e) =>
													setData({ ...data, category_id: e.target.value })
												}
												required
											>
												<option></option>
												{newsCategories?.map((news) => {
													return <option value={news?.id}>{news?.name}</option>;
												})}
											</select>
										</div>
										<div className="w-full">
											<label className="block mb-2 text-sm font-medium text-gray-900">
												Title
											</label>
											<input
												value={data?.title}
												onChange={(e) =>
													setData({ ...data, title: e.target.value })
												}
												type="text"
												className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
												required
											/>
										</div>
										<div className="w-full mt-3">
											<label className="block mb-2 text-sm font-medium text-gray-900">
												Description
											</label>
											<div className="relative w-full mb-5 group border border-black">
											<DraftEditor
													onChange={setDraft}
													title={desc}
													customize={true}
												/>
											</div>
											{draft === "<p></p>\n" && (
												<div className="text-xs text-red-500">
													This Field is required!
												</div>
											)}
										</div>
										<div className="w-full mt-3">
											<label className="block mb-2 text-sm font-medium text-gray-900">
												Thumbnail
											</label>
											<input
												id="dropzone-file"
												type="file"
												accept=".jpeg, .jpg, .png, .gif, image/jpeg, image/jpg, image/png, image/gif"
												className="bg-transparent border resize-none border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-full p-2.5"
												name="support_files"
												onChange={handleFileChange}
												max={1}
											/>
											<div className="text-xs text-red-500">{size}</div>
											{modal === "Update" && !imagePreview && (
												<img
													className="ml-4 mt-4 w-14 h-14 rounded-full"
													src={`${data?.thumbnail}`}
													alt="img"
												/>
											)}
											{imagePreview && (
												<img
													src={imagePreview}
													alt="Uploaded Preview"
													className="ml-4 mt-4 w-14 h-14 rounded-full"
												/>
											)}
											<div></div>
										</div>
									</div>
								</div>
								<div className="bg-transparent px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
									<button
										type="submit"
										className="inline-flex w-full justify-center rounded-md bg-orange-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-orange-500 sm:ml-3 sm:w-auto"
									>
										{isLoading?.addNewsLetter ? (
											<Loader w="6" h="6" color="orange" />
										) : modal === "Add" ? (
											"Add"
										) : (
											"Update"
										)}
									</button>
									<button
										onClick={() => {
											setShow(false);
											setData({});
										}}
										type="button"
										className="inline-flex w-full justify-center border border-orange-600 rounded-md bg-white px-3 py-2 text-sm font-semibold text-orange-600 shadow-sm hover:opacity-90 sm:ml-3 sm:w-auto"
									>
										Back
									</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			)}
		</>
	);
};

export default forwardRef(AddNew);
