import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import {
	fetchJobApplicationData,
	updateApplicationData,
} from "../../../redux/jobsSlice.ts";
import { Loader } from "../../../components/loader/index.js";
import send from "../../../assets/svg/send.svg";
import cross from "../../../assets/svg/cross.svg";
import { convertDateToWords } from "../../../helper/dateFormat.js";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";

export const JobApplication = () => {
	const { jobApplicationData, isLoading } = useSelector((store) => store.jobs);
	const [data, setData] = useState({ status: 0 });
	const dispatch = useDispatch();
	const { id } = useParams();

	const details = [
		{ key: `Applicant's name`, value: jobApplicationData?.name },
		{ key: `Applicant's Email`, value: jobApplicationData?.email },
		{ key: `Applicant's Phone Number`, value: jobApplicationData?.phone },
		{ key: `Address`, value: jobApplicationData?.address },
		{ key: `Linkedin`, value: jobApplicationData?.linkedin },
		{ key: `Short Pitch`, value: jobApplicationData?.short_pitch },
		{
			key: `Notice Period`,
			value: `${jobApplicationData?.notice_period || 0} days`,
		},
		{
			key: `Applied On`,
			value: convertDateToWords(jobApplicationData?.created_at?.slice(0, 10)),
		},
	];

	useEffect(() => {
		dispatch(fetchJobApplicationData(id));
	}, []);

	const statusUpdate = () => {
		dispatch(updateApplicationData({ ...data, id: jobApplicationData?.id }));
	};

	return (
		<>
			<Breadcrumbs
				breadcrumbs={[
					{ name: "Jobs", link: "/jobs/view-all" },
					{
						name: `Applicants (${jobApplicationData?.job?.title || null})`,
						link: `/jobs/view-applicants/${jobApplicationData?.job_id}`,
					},
					{
						name: `Job Application (${jobApplicationData?.name || ""})`,
						link: "",
					},
				]}
			/>
			{isLoading?.jobApplicationData ? (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<Loader h="20" w="20" color={"orange"} />
				</div>
			) : (
				<div className="mb-1 relative px-12">
					<div className="shadow-xl p-4 pb-7 rounded-lg">
						<div className="grid-cols-2 grid gap-y-8">
							{details?.map((detail) => {
								return (
									<div className="flex flex-col justify-center items-start">
										<span className=" text-orange-500">{detail?.key}:</span>
										<span className="text-sm font-light">
											{detail?.key === "Applicant's name" ? (
												<Link
													target="_blank"
													to={`/users/user-detail/${jobApplicationData?.user_id}`}
												>
													{detail?.value}
												</Link>
											) : (
												detail?.value
											)}
										</span>
									</div>
								);
							})}
							<div className="flex flex-col justify-center items-start">
								<span className="text-sm text-orange-500">
									Application Status:
								</span>
								<span
									className={`text-sm font-normal ${
										jobApplicationData?.status === 0
											? "text-orange-600"
											: jobApplicationData?.status === 1
											? "text-green-600"
											: jobApplicationData?.status === 2
											? "text-red-600"
											: null
									}`}
								>
									{jobApplicationData?.status === 0
										? "Pending"
										: jobApplicationData?.status === 1
										? "Accepted"
										: jobApplicationData?.status === 2
										? "Rejected"
										: null}
								</span>
							</div>
							<a
								className="flex justify-center items-center hover:opacity-70 text-white bg-orange-500 border-orange-500 border-2 text-sm px-5 py-2 outline-none rounded-full cursor-pointer w-[200px]"
								href={jobApplicationData?.resume_path}
								target="_blank"
								rel="noreferrer"
								download={`${jobApplicationData?.name}.csv`}
							>
								<svg
									xmlns="http://www.w3.org/2000/svg"
									fill="none"
									viewBox="0 0 24 24"
									stroke-width="1.5"
									stroke="currentColor"
									className="w-5 h-5 mr-2"
								>
									<path
										strokeLinecap="round"
										strokeLinejoin="round"
										d="M12 9.75v6.75m0 0-3-3m3 3 3-3m-8.25 6a4.5 4.5 0 0 1-1.41-8.775 5.25 5.25 0 0 1 10.233-2.33 3 3 0 0 1 3.758 3.848A3.752 3.752 0 0 1 18 19.5H6.75Z"
									/>
								</svg>
								Download CV
							</a>
						</div>
						<div className="flex flex-col justify-start items-start mt-10 gap-y-5">
							{jobApplicationData?.job_questions?.map((quest, index) => {
								return (
									<div className="flex flex-col justify-center items-start gap-y-2">
										<div className="text-orange-500">
											Question {index + 1}){" "}
											<span style={{wordBreak: 'break-word'}} className="text-sm text-black">
												{quest?.question}
											</span>
										</div>
										<div className="text-orange-500">
											Answer:{" "}
											<span className="text-sm text-black">
												{quest?.answer_type === "varchar"
													? jobApplicationData?.candidate_answers[index]?.answer
													: jobApplicationData?.candidate_answers[index]
															?.answer === "1"
													? "Yes"
													: "No"}
											</span>{" "}
										</div>
									</div>
								);
							})}
						</div>
						{jobApplicationData?.admin_message && (
							<div className="text-orange-500 mt-6">
								(
								<Link
									target="_blank"
									to={`/users/user-detail/${jobApplicationData?.admin_id}`}
								>
									Admin Response:
								</Link>
								<span
									className={`${
										jobApplicationData?.status === 0
											? "text-orange-600"
											: jobApplicationData?.status === 1
											? "text-green-600"
											: jobApplicationData?.status === 2
											? "text-red-600"
											: null
									} ml-2`}
								>
									{jobApplicationData?.admin_message})
								</span>
							</div>
						)}
						{jobApplicationData?.status === 0 && data?.status === 0 && (
							<div className="flex justify-start items-center gap-x-4 mt-8">
								<button
									onClick={() => setData({ ...data, status: 1 })}
									className={`shrink-0
						border-green-600 text-green-600 hover:bg-green-600
				bg-transparent px-3 py-1 border-2 rounded-full hover:text-white transition-all duration-200 ease-in-out`}
								>
									Approve
								</button>
								<button
									onClick={() => setData({ ...data, status: 2 })}
									className={`shrink-0
						border-red-600 text-red-600 hover:bg-red-600
				bg-transparent px-3 py-1 border-2 rounded-full hover:text-white transition-all duration-200 ease-in-out`}
								>
									Reject
								</button>
							</div>
						)}
						{!!data?.status && jobApplicationData?.status === 0 && (
							<div className="flex justify-start items-start flex-col my-6 gap-y-2">
								<span
									className={`${
										data?.status === 1 ? "text-green-600" : "text-red-500"
									}`}
								>
									{data?.status === 1
										? "Approval Message:"
										: "Reason to Reject"}
								</span>
								<div className="flex flex-col justify-center items-start w-full gap-y-8">
									<textarea
										onChange={(e) =>
											setData({ ...data, message: e.target.value })
										}
										maxLength={1000}
										className={`p-2.5 min-h-[130px] max-h-[130px] w-full text-sm text-gray-900 bg-transparent border-2 ${
											data?.status === 1 ? "border-green-500" : "border-red-500"
										}  appearance-none focus:outline-none focus:ring-0 peer`}
										placeholder=" "
									/>
									<div className="flex justify-center items-start gap-x-3">
										<button
											onClick={() => setData({ ...data, status: 0 })}
											className={`shrink-0 text-red-600 bg-red-600 px-2 py-1 rounded-lg hover:opacity-80 transition-all duration-200 ease-in-out`}
										>
											<img src={cross} className="h-[24px]" />
										</button>
										<button
											onClick={() => statusUpdate()}
											className={`shrink-0 text-orange-600 bg-orange-600 px-3 py-1 rounded-lg hover:opacity-80 transition-all duration-200 ease-in-out`}
										>
											{isLoading?.updateApplicationData ? (
												<Loader w={"6"} h={"6"} color={"orange"} />
											) : (
												<img src={send} className="h-[24px] fill-orange-600" />
											)}
										</button>
									</div>
								</div>
							</div>
						)}
					</div>
				</div>
			)}
		</>
	);
};
