import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	addAdmin,
	deleteAdmin,
	fetchAdminRoles,
} from "../../../redux/users.ts";
import { Loader } from "../../../components/loader/index.js";
import { Pagination } from "../../../components/pagination/index.js";
import { UsersList } from "../../../components/usersList.js/index.js";
import SwitchModal from "./switchModal/index.js";
import { useRef } from "react";
import DeleteModal from "../../../components/deleteModal/index.js";
import { ChangePassword } from "./changePassword/index.js";
import { AddBtn } from "../../../components/addBtn/index.js";
import { isEmpty } from "../../../helper/join.js";
import { Table } from "../../../components/table/index.jsx";
import { Breadcrumbs } from "../../../components/breadcrumbs/index.jsx";
import { toastNotification } from "../../../services/notification-service.js";

export const AdminUserRoles = () => {
	const { adminRoles, isLoading } = useSelector((store) => store.user);
	const { userInfo: info } = useSelector((store) => store.login);
	const [userInfo, setUserInfo] = useState({});
	const [newAdmin, setNewAdmin] = useState({});
	const [show, setShow] = useState(false);
	const [change, setChange] = useState(false);
	const [param, setParam] = useState({});
	const [open, setOpen] = useState(false);
	const [id, setId] = useState(null);
	const dispatch = useDispatch();
	const popupRef = useRef();

	useEffect(() => {
		setNewAdmin({ ...newAdmin, user_id: userInfo?.userId });
	}, [userInfo]);

	useEffect(() => {
		if (isEmpty(adminRoles)) {
			dispatch(fetchAdminRoles(1));
		}
	}, []);

	function pageChange(page) {
		dispatch(fetchAdminRoles(page));
	}

	const handleDelete = (id) => {
		setId(id);
		if (info?.id === id) {
			toastNotification({
				status: "warning",
				message: "You can't remove yourself from admin role!",
			});
			return;
		}
		popupRef?.current?.open({
			id: id,
			deleteFun: deleteAdmin,
			type: "Admin User",
		});
	};

	const handleStatusChange = (id, role) => {
		setShow(true);
		setId(id);
		setParam({
			role_id: role?.id === 1 ? 2 : 1,
			user_id: id,
		});
	};

	const tableData = [
		{ type: "fullName", to: "/users/user-detail/{id}" },
		{ key: "Username", value: "username" },
		{
			key: "Current Role",
			nested: true,
			value1: "role",
			value2: "name",
		},
		{ key: "Email", value: "email" },
	];

	const actions = [
		{
			type: "adminStatus",
			func: handleStatusChange,
			isLoading: isLoading?.switchAdminRole,
			value: "is_active",
		},
		{ type: "delete", func: handleDelete, isLoading: isLoading?.deleteAdmin },
	];

	const handleAdminAdd = async () => {
		if (
			(adminRoles?.data || [])
				?.map((admin) => admin?.id)
				?.includes(newAdmin?.user_id)
		) {
			return toastNotification({
				status: "error",
				message: "Admin Already Exist",
			});
		}
		const json = await dispatch(
			addAdmin({ params: newAdmin, setOpen: () => setOpen() })
		);
		if (json?.status === 200) {
			setNewAdmin({});
		}
	};

	return (
		<>
			<Breadcrumbs breadcrumbs={[{ name: "Admin Users", link: "" }]} />
			<div className="relative mx-12">
				<DeleteModal ref={popupRef} />
				<ChangePassword change={change} setChange={setChange} />
				<div className="flex justify-between">
					{open ? (
						<div className="flex justify-start items-center space-x-5 my-2">
							<div className="flex flex-col">
								<label className="font-light">Write Username</label>
								<div className="mt-2">
									<UsersList setUserInfo={setUserInfo} />
								</div>
							</div>
							<div className="flex flex-col">
								<label className="font-light">Admin Role</label>
								<select
									placeholder="Seach by name..."
									type="text"
									onChange={(e) =>
										setNewAdmin({ ...newAdmin, role_id: e.target.value })
									}
									value={newAdmin?.role_id || null}
									className="bg-transparent border border-gray-300 text-gray-900 sm:text-sm rounded-lg outline-none focus:border-orange-600 block w-60 p-2.5 mt-2"
								>
									<option value={null}>Select</option>
									<option value={1}>Super Admin</option>
									<option value={2}>Admin</option>
								</select>
							</div>
							<div className="flex flex-col">
								<button
									onClick={() => handleAdminAdd()}
									className="py-2 text-blue-600 border-2 mt-8 rounded-lg px-2 border-blue-600 bg-red hover:bg-blue-600 hover:text-white transition-all duration-200 ease-in-out"
								>
									{isLoading?.addAdmin ? (
										<Loader w="6" h="6" color={"blue"} />
									) : (
										"Add New Admin"
									)}
								</button>
							</div>
						</div>
					) : (
						<div className="w-[140px] mt-5">
							<AddBtn func={() => setOpen(true)} text={"Add Admin"} />
						</div>
					)}
					<button
						onClick={() => setChange(true)}
						className="flex justify-center items-center gap-2 transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-125 duration-300 "
					>
						<svg
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 24 24"
							strokeWidth="1.5"
							stroke="currentColor"
							className="w-6 h-6"
						>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M9.594 3.94c.09-.542.56-.94 1.11-.94h2.593c.55 0 1.02.398 1.11.94l.213 1.281c.063.374.313.686.645.87.074.04.147.083.22.127.325.196.72.257 1.075.124l1.217-.456a1.125 1.125 0 0 1 1.37.49l1.296 2.247a1.125 1.125 0 0 1-.26 1.431l-1.003.827c-.293.241-.438.613-.43.992a7.723 7.723 0 0 1 0 .255c-.008.378.137.75.43.991l1.004.827c.424.35.534.955.26 1.43l-1.298 2.247a1.125 1.125 0 0 1-1.369.491l-1.217-.456c-.355-.133-.75-.072-1.076.124a6.47 6.47 0 0 1-.22.128c-.331.183-.581.495-.644.869l-.213 1.281c-.09.543-.56.94-1.11.94h-2.594c-.55 0-1.019-.398-1.11-.94l-.213-1.281c-.062-.374-.312-.686-.644-.87a6.52 6.52 0 0 1-.22-.127c-.325-.196-.72-.257-1.076-.124l-1.217.456a1.125 1.125 0 0 1-1.369-.49l-1.297-2.247a1.125 1.125 0 0 1 .26-1.431l1.004-.827c.292-.24.437-.613.43-.991a6.932 6.932 0 0 1 0-.255c.007-.38-.138-.751-.43-.992l-1.004-.827a1.125 1.125 0 0 1-.26-1.43l1.297-2.247a1.125 1.125 0 0 1 1.37-.491l1.216.456c.356.133.751.072 1.076-.124.072-.044.146-.086.22-.128.332-.183.582-.495.644-.869l.214-1.28Z"
							/>
							<path
								strokeLinecap="round"
								strokeLinejoin="round"
								d="M15 12a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
							/>
						</svg>
						<div className="text-sm">Change Password</div>
					</button>
				</div>
				<div className="h-[calc(100vh-270px)] overflow-auto mt-3">
					<Table
						data={tableData}
						table={adminRoles}
						actions={actions}
						id={id}
						isLoading={isLoading?.adminRoles}
					/>
				</div>
				<Pagination
					total={adminRoles?.last_page}
					current={adminRoles?.current_page}
					func={pageChange}
				/>
				<SwitchModal show={show} setShow={setShow} params={param} />
			</div>
		</>
	);
};
