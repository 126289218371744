import camera from "../../../assets/svg/camera.svg";
import { CiCirclePlus } from "react-icons/ci";
import { IoPersonSharp } from "react-icons/io5";

export const MemberPicture = ({ id, addNewChild, payload, openModal, popupRef }) => {
	return (
		<div
			onClick={() => openModal(payload)}
			className="flex justify-start gap-x-3 items-center relative"
		>
			<button
				onClick={(e) => {
					e.stopPropagation();
                    popupRef.current.open(id, payload);
					//addNewChild(id, payload);
				}}
				className="absolute top-6 left-[10px]"
			>
				<CiCirclePlus size={16} />
			</button>
			<label
				className={`w-10 h-10 cursor-pointer -mt-5 flex justify-center items-center rounded-full`}
				htmlFor={"profile_image"}
			>
				<IoPersonSharp
					color="orange"
					className={`w-6 h-6 rounded-full`}
					id={id}
				/>
				{/* {data?.profile_image && (
					<div className="w-20 h-20 group hover:bg-gray-200 opacity-60 rounded-full absolute flex justify-center items-center cursor-pointer transition duration-500">
						<img
							className="hidden group-hover:block w-5"
							src="https://www.svgrepo.com/show/33565/upload.svg"
							alt=""
						/>
					</div>
				)} */}
			</label>
		</div>
	);
};
