import { useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { fetchQuestionDetails } from "../../../../redux/users.ts";
import { Loader } from "../../../../components/loader/index.js";
import { Breadcrumbs } from "../../../../components/breadcrumbs/index.jsx";
import { Disclosure, DisclosureButton } from "@headlessui/react";

export const QuestionDetails = () => {
	const { id } = useParams();
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const { questionDetails, isLoading } = useSelector((store) => store.user);

	useEffect(() => {
		dispatch(fetchQuestionDetails(id));
	}, []);

	return (
		<>
			<Breadcrumbs
				breadcrumbs={[
					{ name: "Questions", link: "/users/overall" },
					{ name: "Question Details", link: "" },
				]}
			/>
			{isLoading?.questionDetails ? (
				<div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
					<Loader h="20" w="20" color={"orange"} />
				</div>
			) : (
				<div>
					<div className="mx-12 border-2 border-orange-600 pb-10">
						<Disclosure>
							<DisclosureButton className="bg-gray-100 flex justify-start items-start w-full">
								<div className=" ml-8 flex justify-center space-x-2 p-3 text-left">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										fill="none"
										viewBox="0 0 24 24"
										strokeWidth="1.5"
										stroke="#F97316"
										className="w-6 h-6"
									>
										<path
											strokeLinecap="round"
											strokeLinejoin="round"
											d="M15.75 6a3.75 3.75 0 1 1-7.5 0 3.75 3.75 0 0 1 7.5 0ZM4.501 20.118a7.5 7.5 0 0 1 14.998 0A17.933 17.933 0 0 1 12 21.75c-2.676 0-5.216-.584-7.499-1.632Z"
										/>
									</svg>
									<div className="text-orange-500">Question Owner Details</div>
								</div>
							</DisclosureButton>
							<table className="w-full text-sm text-left rtl:text-right text-gray-500">
								<thead className="text-xs text-gray-700 uppercase bg-gray-50">
									<tr>
										<th scope="col" className="px-6 py-3 text-center">
											Profile Image
										</th>
										<th scope="col" className="px-6 py-3 text-center">
											Name
										</th>
										<th scope="col" className="px-6 py-3 text-center">
											Username
										</th>
										<th scope="col" className="px-6 py-3 text-center">
											Email Address
										</th>
										<th scope="col" className="px-6 py-3 text-center">
											Posted Question
										</th>
										<th scope="col" className="px-6 py-3 text-center">
											Followers
										</th>
										<th scope="col" className="px-6 py-3 text-center">
											Actions
										</th>
									</tr>
								</thead>
								<tbody>
									<tr className="bg-white border-b hover:bg-gray-50">
										<th className="px-6 py-4 flex justify-center">
											<img
												src={
													questionDetails?.AllPosts?.user_data?.profile_photo
												}
												alt="profile"
												className="rounded-full w-12 h-12"
											/>
										</th>
										<th className="px-6 py-4 text-center">
											<Link
												to={`https://web.procurementleague.com/profile/${questionDetails?.AllPosts?.user_data?.username}`}
												target="_blank"
												rel="noopener noreferrer"
											>
												{questionDetails?.AllPosts?.user_data?.firstname +
													" " +
													questionDetails?.AllPosts?.user_data?.lastname}
											</Link>
										</th>
										<td className="px-6 py-4 text-center">
											{questionDetails?.AllPosts?.user_data?.username}
										</td>
										<td className="px-6 py-4 text-center">
											{questionDetails?.AllPosts?.user_data?.email}
										</td>
										<td className="px-6 py-4 text-center">
											{questionDetails?.AllPosts?.question_posted}
										</td>
										<td className="px-6 py-4 text-center">
											{questionDetails?.AllPosts?.count_followers}
										</td>
										<td className="px-6 py-4 text-center">
											<button
												onClick={() =>
													navigate(
														`/users/user-detail/${questionDetails?.AllPosts?.user_data?.id}`
													)
												}
												className="py-2 text-blue-600 border-2 rounded-lg px-2 border-blue-600 bg-red hover:bg-blue-600 hover:text-white transition-all duration-200 ease-in-out"
											>
												View User
											</button>
										</td>
									</tr>
								</tbody>
							</table>
							<div className="mx-9 mt-6">
								<div className="text-lg font-[700]">Question Details</div>
								<div className="flex flex-col border gap-3 rounded-lg p-2">
									<div className="font-bold">{questionDetails?.question_new?.description}</div>
									<div
										dangerouslySetInnerHTML={{
											__html: questionDetails?.question_new?.question,
										}}
									></div>
									<div className="flex justify-start space-x-3">
										<div className="flex justify-center items-center">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 16 16"
												fill="currentColor"
												className="w-4 h-4 ml-1.5"
											>
												<path d="M1 8.849c0 1 .738 1.851 1.734 1.947L3 10.82v2.429a.75.75 0 0 0 1.28.53l1.82-1.82A3.484 3.484 0 0 1 5.5 10V9A3.5 3.5 0 0 1 9 5.5h4V4.151c0-1-.739-1.851-1.734-1.947a44.539 44.539 0 0 0-8.532 0C1.738 2.3 1 3.151 1 4.151V8.85Z" />
												<path d="M7 9a2 2 0 0 1 2-2h4a2 2 0 0 1 2 2v1a2 2 0 0 1-2 2h-.25v1.25a.75.75 0 0 1-1.28.53L9.69 12H9a2 2 0 0 1-2-2V9Z" />
											</svg>
											<div>{questionDetails?.question_new?.count_answers}</div>
										</div>
										<div className="flex justify-center items-center">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 16 16"
												fill="currentColor"
												className="w-4 h-4 ml-1.5"
											>
												<path d="M2.09 15a1 1 0 0 0 1-1V8a1 1 0 1 0-2 0v6a1 1 0 0 0 1 1ZM5.765 13H4.09V8c.663 0 1.218-.466 1.556-1.037a4.02 4.02 0 0 1 1.358-1.377c.478-.292.907-.706.989-1.26V4.32a9.03 9.03 0 0 0 0-2.642c-.028-.194.048-.394.224-.479A2 2 0 0 1 11.09 3c0 .812-.08 1.605-.235 2.371a.521.521 0 0 0 .502.629h1.733c1.104 0 2.01.898 1.901 1.997a19.831 19.831 0 0 1-1.081 4.788c-.27.747-.998 1.215-1.793 1.215H9.414c-.215 0-.428-.035-.632-.103l-2.384-.794A2.002 2.002 0 0 0 5.765 13Z" />
											</svg>
											<div>
												{questionDetails?.question_new?.like_vote_question}
											</div>
										</div>
										{/* <div className="flex justify-center items-center">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 16 16"
												fill="currentColor"
												className="w-4 h-4"
											>
												<path d="M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3Z" />
												<path
													fillRule="evenodd"
													d="M1.38 8.28a.87.87 0 0 1 0-.566 7.003 7.003 0 0 1 13.238.006.87.87 0 0 1 0 .566A7.003 7.003 0 0 1 1.379 8.28ZM11 8a3 3 0 1 1-6 0 3 3 0 0 1 6 0Z"
													clip-rule="evenodd"
												/>
											</svg>
											<div>{questionDetails?.question_new?.views}</div>
										</div>
										<div className="flex justify-center items-center">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 16 16"
												fill="currentColor"
												className="w-4 h-4"
											>
												<path d="M12 6a2 2 0 1 0-1.994-1.842L5.323 6.5a2 2 0 1 0 0 3l4.683 2.342a2 2 0 1 0 .67-1.342L5.995 8.158a2.03 2.03 0 0 0 0-.316L10.677 5.5c.353.311.816.5 1.323.5Z" />
											</svg>
											<div>{questionDetails?.question_new?.share}</div>
										</div> */}
										<div className="flex justify-center items-center">
											<svg
												xmlns="http://www.w3.org/2000/svg"
												viewBox="0 0 16 16"
												fill="currentColor"
												className="w-4 h-4"
											>
												<path
													fillRule="evenodd"
													d="M1 8a7 7 0 1 1 14 0A7 7 0 0 1 1 8Zm7.75-4.25a.75.75 0 0 0-1.5 0V8c0 .414.336.75.75.75h3.25a.75.75 0 0 0 0-1.5h-2.5v-3.5Z"
													clipRule="evenodd"
												/>
											</svg>
											<div>{questionDetails?.question_new?.created_at}</div>
										</div>
									</div>
									<div className="flex justify-start gap-3">
										{questionDetails?.question_new?.tags?.map((tag) => {
											return (
												<div
													className="text-blue-500 underline"
												>
													{tag?.tag_title}
												</div>
											);
										})}
									</div>
								</div>
							</div>

							<div className="flex flex-col mx-9 mt-6 gap-3">
								<div className="text-lg font-[700]">Answers</div>
								{questionDetails?.get_answers?.length === 0 && (
									<div className="text-gray-500">No answers to display</div>
								)}
								{questionDetails?.get_answers?.map((ans) => {
									return (
										<div className="grid grid-cols-10 h-max">
											<div className="col-span-1">
												<img
													src={ans?.profile_photo}
													alt="profile"
													className="rounded-full w-16 h-16 border border-orange-400"
												/>
											</div>
											<div className="col-span-9 flex flex-col border rounded-lg p-2">
												<div className="text-orange-500 text-lg">
													{ans?.firstname} {ans?.lastname}
												</div>
												<div
													dangerouslySetInnerHTML={{
														__html: ans?.answer,
													}}
												></div>
												<div className="flex justify-start space-x-3 mt-2">
													<div className="flex justify-center items-center">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 16 16"
															fill="currentColor"
															className="w-4 h-4 ml-1.5"
														>
															<path d="M2.09 15a1 1 0 0 0 1-1V8a1 1 0 1 0-2 0v6a1 1 0 0 0 1 1ZM5.765 13H4.09V8c.663 0 1.218-.466 1.556-1.037a4.02 4.02 0 0 1 1.358-1.377c.478-.292.907-.706.989-1.26V4.32a9.03 9.03 0 0 0 0-2.642c-.028-.194.048-.394.224-.479A2 2 0 0 1 11.09 3c0 .812-.08 1.605-.235 2.371a.521.521 0 0 0 .502.629h1.733c1.104 0 2.01.898 1.901 1.997a19.831 19.831 0 0 1-1.081 4.788c-.27.747-.998 1.215-1.793 1.215H9.414c-.215 0-.428-.035-.632-.103l-2.384-.794A2.002 2.002 0 0 0 5.765 13Z" />
														</svg>
														<div>{ans?.answer_like}</div>
													</div>
													<div className="flex justify-center items-center">
														<svg
															xmlns="http://www.w3.org/2000/svg"
															viewBox="0 0 16 16"
															fill="currentColor"
															className="w-4 h-4"
														>
															<path
																fillRule="evenodd"
																d="M1 8a7 7 0 1 1 14 0A7 7 0 0 1 1 8Zm7.75-4.25a.75.75 0 0 0-1.5 0V8c0 .414.336.75.75.75h3.25a.75.75 0 0 0 0-1.5h-2.5v-3.5Z"
																clipRule="evenodd"
															/>
														</svg>
														<div>{ans?.updated_at}</div>
													</div>
												</div>
											</div>
										</div>
									);
								})}
							</div>
						</Disclosure>
					</div>
				</div>
			)}
		</>
	);
};
